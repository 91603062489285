<template>
<div class="flex items-end gap-2">
  <button type="button" class="flex items-center justify-center text-cx3 hover:opacity-80 cursor-pointer"
    @click.prevent="click(product.number)">
    <HeartIcon class="w-7 h-7 text-cx4-primary flex-shrink-0" :class="{ 'fill-cx4-primary': isInlist }" />
  </button>
  <div v-if="showWishTooltip" class="mt-4 text-sm font-medium text-cx1-primary">
    <span class="text-sm font-medium text-cx1-primary">
      <a class="font-bold text-cx1-primary underline" :href="lc('/login')">
        {{ trans('login') }}
      </a>
      {{ trans('or') }}
      <a class="text-bold text-cx1-primary underline" :href="lc('/register')">
        {{ trans('register') }}
      </a>
      &nbsp;{{ trans('first') }}
    </span>
  </div>
</div>
</template>

<script setup>
import { toggleWish, inWishlist, useWishlist } from "g/Composables/Wishlist.js";
import { computed, watch } from "vue";
import { usePage } from "@inertiajs/vue3";
import { useLocale } from "g/Composables/GlobalLocale.js";
import { HeartIcon } from "@heroicons/vue/outline/index.js";

const { lc } = useLocale();

const $page = usePage();

const props = defineProps({
  product: {
    type: Object,
    required: true,
  },
  text: {
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits(['disableEffects']);

const { wishListLoginError } = useWishlist();

const showWishTooltip = computed(() => {
  return wishListLoginError.value?.includes(props.product.number) ?? null;
});

const isInlist = computed(() => {
  const p = props.product;
  return inWishlist(p.number) || $page?.component == 'Account' && (p.isVariation || p.isPackageProduct);
});

const click = (number) => {
  toggleWish(number);
};

watch(() => showWishTooltip.value, (newValue) => emits('disableEffects', newValue));

</script>
