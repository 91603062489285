<!-- eslint-disable vue/no-v-html -->
<template>
<div>
  <header ref="header" class="relative">
    <nav aria-label="Top">

      <!-- info navigation -->
      <NvFirstNav v-if="!overlay.mobile" />
      <!-- / info navigation -->

      <!-- Top menu -->
      <div class="flex flex-col justify-center h-[70px] lg:min-h-[100px] bg-white z-50">
        <div id="secondnav" class="bg-white left-0 right-0 top-0">
          <div class="max-w-default mx-auto px-4 lg:px-10">
            <div class="h-14 flex lg:gap-4 items-center justify-between">

              <!--  logo -->
              <div class="flex lg:items-center mr-8 flex-shrink">
                <a :href="`/${$page.props.locale}`" class="max-h-11">
                  <img :src="placeholderUrl" class="object-scale-down h-[24px] lg:h-[48px] max-w-[200px] lg:max-w-none"
                    alt="logo" />
                </a>
              </div>

              <div class="flex flex-1 items-center justify-end sm:justify-start gap-7 lg:gap-8">
                <!-- Mobile hamburger menu -->
                <button class="2xl:hidden -m-2 p-2 flex gap-2 items-center rounded active:bg-gray-50 hover:bg-gray-50"
                  @click="toggleMobilemenu">
                  <div class="flex justify-center items-center h-6 w-6" :class="{ 'fill-cx3-primary': overlay.mobile }">
                    <HamburgerIcon />
                  </div>
                </button>

                <!-- Top menu -->
                <div class="hidden 2xl:flex">
                  <MegaMenu :items="mainmenu" />
                </div>

                <div class="hidden lg:block border-r border-black h-full">&nbsp;</div>

                <!-- Account -->
                <Link class="-m-2 p-2 flex items-center rounded hover:bg-gray-50" :href="accountLink">
                <AccountIcon class="flex-shrink-0 h-6 w-6 text-cx1-primary" />
                </Link>

                <!-- Cart -->
                <button class="-m-2 p-2 flex items-center rounded hover:bg-gray-50" @click="toggleSidemenu">
                  <CartIcon class="flex-shrink-0 h-6 w-6 text-cx1-primary" />
                </button>

                <button class="flex lg:hidden -m-2 p-2 items-center rounded hover:bg-gray-50"
                  @click="toggleMobileSearch">
                  <SearchIcon v-if="!overlay.mobileSearch" class="flex-shrink-0 h-6 w-6 text-cx1-primary" />
                  <XIcon v-else class="flex-shrink-0 h-6 w-6 text-cx1-primary" />
                </button>

                <!-- Wishlisth -->
                <Component :is="wishlistLink ? 'Link' : 'div'" :href="wishlistLink"
                  class="relative hidden lg:flex -m-2 p-2 items-center rounded hover:bg-gray-50"
                  @click="wishlistLink ? null : showWishTooltip = !showWishTooltip">
                  <WishlistIcon class="flex-shrink-0 h-6 w-6 text-cx1-primary" />
                  <MenuTooltip :open="showWishTooltip" @close="showWishTooltip = false">
                    <span class="text-sm font-medium leading-loose text-cx1-primary">
                      <a class="font-bold text-cx1-primary underline" href="/login">
                        {{ trans('login') }}
                      </a>
                      {{ trans('or') }}
                      <a class="text-cx1-primary underline" href="/register">
                        {{ trans('register') }}
                      </a>
                      {{ trans('first') }}
                    </span>
                  </MenuTooltip>
                </Component>

                <!-- country -->
                <NavCountrySelect v-if="device.isDesktop" />

                <div class="hidden lg:block border-r border-black h-full">&nbsp;</div>

                <!-- Search -->
                <div class="relative hidden items-center justify-center lg:flex">
                  <div class="w-full relative max-w-3xl">
                    <div class="absolute inset-y-0 left-0 pl-3 flex items-center z-20 cursor-pointer" @click="search">
                      <SearchIcon class="h-5 w-5 text-gray-400" />
                    </div>
                    <input id="search" v-model="searchTerm" name="search"
                      class="input-primary h-[45px] block placeholder:text-[#B2B0B0] w-full pl-10 pr-3 py-2 border border-gray-400 leading-5 bg-white shadow-sm focus:ring-1 focus:ring-gray-800 focus:border-gray-800 font-bold text-sm text-black"
                      :placeholder="trans('search_products')" type="search" @keyup.enter="search" />
                  </div>


                </div>

              </div>

            </div>
          </div>
        </div>
      </div>
      <!-- /Top menu -->

      <!-- Mobile search -->
      <div v-if="overlay.mobileSearch" class="bg-white pb-4 flex lg:hidden h-full w-full items-center justify-center">
        <div class="mx-4 w-full relative">
          <div class="absolute inset-y-0 right-0 pr-3 flex items-center z-20 cursor-pointer" @click="search">
            <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
              fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clip-rule="evenodd"></path>
            </svg>
          </div>
          <input id="mobilesearch" ref="mobileSearchRef" v-model="searchTerm" name="mobilesearch"
            class="block w-full pr-10 pl-3 py-2 border border-gray-300 rounded leading-5 bg-white shadow-sm placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-cx1-primary focus:border-cx1-primary sm:text-sm"
            :placeholder="trans('search')" type="search" />
        </div>
      </div>
    </nav>
  </header>

  <div class="relative z-40">
    <MobileMenu />
  </div>

</div>

<QuickSearchDropdown />

<CartSidemenu :visible="overlay.cart" @hide="overlay.cart = false" />
</template>

<script setup>
import { ref, computed, watch, provide } from "vue";
import { router } from "@inertiajs/vue3";
import { usePage } from "@inertiajs/vue3";
import { useDevice } from "g/Composables/GlobalDevice";
import { placeholderUrl } from "@/Composables/Swap.js";
import { useCart } from "g/Store/GlobalUseCart.js";
import { useOverlay } from "@/Composables/Overlay.js";
import { useStore } from "g/Composables/GlobalStore.js";
import { XIcon } from "@heroicons/vue/solid/index.js";
import { useFixed } from "@/Composables/Shared/isFixed.js";
import MegaMenu from "@/Components/Nav/MegaMenu.vue";
import MobileMenu from "@/Components/Nav/MobileMenu.vue";
import QuickSearchDropdown from "@/Shared/QuickSearchDropdown.vue";
import CartSidemenu from "@/Shared/CartSidemenu.vue";
import WishlistIcon from "@/Icons/WishlistIcon.vue";
import AccountIcon from "@/Icons/AccountIcon.vue";
import SearchIcon from "@/Icons/SearchIcon.vue";
import CartIcon from "@/Icons/CartIcon.vue";
import MenuTooltip from "@/Shared/MenuTooltip.vue";
import NavCountrySelect from "@/Shared/NavCountrySelect.vue";
import NvFirstNav from "@/Components/Nav/NvFirstNav.vue";
import HamburgerIcon from "@/Icons/HamburgerIcon.vue";


const $page = usePage();

const store = useStore();

const cart = useCart();

const { isFixed, navHeight } = useFixed();

const { device } = useDevice();

const { overlay, setOverlay } = useOverlay();

const mobileSearchRef = ref(null);
const showWishTooltip = ref(false);
// const showCountryTooltip = ref(false);

// const infoAutoplay = {
//   delay: 3000,
//   disableOnInteraction: false,
//   speed: 1000,
// };

const mainmenu = ref(store['mainmenu']);

const loggedIn = computed(() => $page.props.logged_in);

const accountLink = computed(() => loggedIn.value ? "/account" : "/login");

const wishlistLink = computed(() => loggedIn.value ? "/account/wishlist" : null);

const pageComponentName = computed(() => $page.component);

watch(() => cart.value.amount, (newAmount) => {
  if (pageComponentName.value != "Cart" && newAmount > 0 && $page.props.flash.event !== 'login') {
    overlay.cart = true;
  } else {
    // dev fixes scroll lock issue when removing last item from cart
    setOverlay('cart', false);
  }
});

const searchTerm = ref("");

const search = () => (searchTerm.value) ? router.visit("/search?q=" + searchTerm.value) : null;

const toggleMobilemenu = () => setOverlay('mobile', overlay.mobile, true);

const toggleMobileSearch = () => {
  setOverlay('mobileSearch', overlay.mobileSearch, true);
  //setOverlay('search', overlay.search, true);
  setTimeout(() => {
    if (mobileSearchRef.value) {
      mobileSearchRef.value.focus();
    }
  }, 0);
};

const toggleSidemenu = () => {
  let a = Object.keys((cart.value?.cartitems ?? {})).length;
  setOverlay('cart', !!a, null, ['megamenu']);
};


provide('Nav', {
  overlay,
  setOverlay,
  searchTerm,
  store,
  device,
  isFixed,
  navHeight,
  search,
});

</script>
