<template>
<div class="flex w-full items-center justify-start border-b-[0.5px] border-cx4-primary">
  <div class="flex items-center cursor-pointer py-2.5 w-full" :class="{ 'text-xl font-bold' : parseInt(selectedItem?.level) == 0}">
    <div v-if="item.children && item.children?.length" class="flex items-center justify-between flex-1" @click="toggleChildren(item)">
      <div>{{ item.name }}</div>
      <ChevronRightIcon class="ml-auto shrink-0 h-5 w-5 lg:h-6 lg:w-6" />
    </div>
    <Link v-else class="hover:underline" :href="item.value ?? '#'">{{ item.name }}</Link>
  </div>
</div>
</template>

<script setup>
import { inject } from "vue";
import { ChevronRightIcon } from "@heroicons/vue/solid/index.js";

defineProps({
  item: {
    type: Object,
    required: true,
  },

});

const { previousItems, selectedItem } = inject('MobileGroupTree', null);

const toggleChildren = (item) => {

  previousItems.value.push(item);

  selectedItem.value = item;

};

</script>
