<template>
<div v-if="overlay.search  && data.openedOnce"
  class="transform top-0 right-0 w-screen bg-white fixed h-full overflow-auto z-50 flex flex-col">

  <div class="w-full h-auto max-h-[276px] lg:max-h-[478px] bg-cx1-primary text-cx2-primary px-3 xl:px-[140px]">

    <div class="max-w-default mx-auto">

      <div class="grid grid-cols-3 items-center justify-center py-3 lg:py-24">

        <div class="hidden lg:block col-span-1">
          <div class="flex lg:items-center mr-8">
            <a :href="`/${$page.props.locale}`" class="max-h-11">
              <img :src="placeholderUrl" height="48"
                class="object-scale-down h-[48px] max-w-[200px] lg:max-w-none brightness-0 invert" alt="logo" />
            </a>
          </div>
        </div>

        <div class="col-span-3 lg:col-span-1">
          <div class="flex flex-col relative lg:max-w-[671px] lg:mx-auto">
            <svg class="absolute inset-y-auto bottom-4 left-3 flex items-center z-10 cursor-pointer" width="18"
              height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" @click="search">
              <path
                d="M16.6 18L10.3 11.7C9.8 12.1 9.225 12.4167 8.575 12.65C7.925 12.8833 7.23333 13 6.5 13C4.68333 13 3.146 12.371 1.888 11.113C0.629333 9.85433 0 8.31667 0 6.5C0 4.68333 0.629333 3.14567 1.888 1.887C3.146 0.629 4.68333 0 6.5 0C8.31667 0 9.85433 0.629 11.113 1.887C12.371 3.14567 13 4.68333 13 6.5C13 7.23333 12.8833 7.925 12.65 8.575C12.4167 9.225 12.1 9.8 11.7 10.3L18 16.6L16.6 18ZM6.5 11C7.75 11 8.81267 10.5627 9.688 9.688C10.5627 8.81267 11 7.75 11 6.5C11 5.25 10.5627 4.18733 9.688 3.312C8.81267 2.43733 7.75 2 6.5 2C5.25 2 4.18733 2.43733 3.312 3.312C2.43733 4.18733 2 5.25 2 6.5C2 7.75 2.43733 8.81267 3.312 9.688C4.18733 10.5627 5.25 11 6.5 11Z"
                fill="#B2B0B0" />
            </svg>
            <div class="flex flex-row items-center justify-between gap-4">
              <input ref="thisSearch" v-model="searchTerm" name="search" :placeholder="trans('search')"
                class="input-primary text-cx1-primary pl-10 h-[55px]" type="search">
              <div class="lg:hidden">
                <div type="button"
                  class="text-right text-cx4-primary hover:underline cursor-pointer text-sm lg:text-base font-bold"
                  @click="hide">
                  {{ trans('cancel') }}
                </div>
              </div>
            </div>
          </div>

          <div v-if="false" class="text-cx2-primary flex flex-col gap-4">
            <span class="text-sm lg:text-base text-cx4-primary mt-4 lg:mt-2 ">Articles & Stories</span>
            <div class="flex flex-col gap-4 text-sm lg:text-xl font-bold">
              <!-- TODO & limit to 4 -->
              <span>Hakutulos mikä ei ole tuote</span>
              <span>Toinen artikkeli tms</span>
              <span>Dolor sit</span>
              <span>Lorem ipsum</span>
            </div>
          </div>
        </div>

        <div class="hidden lg:block col-span-1 ml-auto">
          <div type="button"
            class="text-right text-cx4-primary hover:underline cursor-pointer text-sm lg:text-base font-bold w-fit"
            @click="hide">
            {{ trans('cancel') }}
          </div>
        </div>

      </div>

    </div>


  </div>

  <div  class="bg-white  w-full px-3 xl:px-[140px]">

    <div class="max-w-default mx-auto relative">

      <div class="flex flex-row items-center justify-between py-3 lg:py-14 mt-2.5">
        <h3 class="text-2xl sm:text-3xl lg:text-5xl font-bold">{{ trans('top_results') }}</h3>
        <button
          class="flex items-center justify-center gap-2 text-center px-8 py-[1.1rem] btn-primary text-sm font-semibold cursor-pointer min-w-[146px]"
          :disabled="!data.quicksearchProducts.length" @click="search">
          {{ trans('view_all') }}
        </button>
      </div>

      <div v-if="data.quicksearchProducts.length"
        class=" justify-center w-full h-full bg-white text-cx1-primary text-sm pt-2 ">
        <div class="grid grid-cols-2 gap-3 sm:gap-y-10 sm:gap-x-4 md:grid-cols-4 xl:grid-cols-4 2xl:grid-cols-6">
          <ProductContainer v-for="product in data.quicksearchProducts" :key="product.id" :product="product" />
        </div>
      </div>
      <div v-else>
        <h3 class="text-xl lg:text-3xl font-bold text-center mt-4 lg:mt-12">{{ trans('no_results') }}</h3>
      </div>

      <div class="flex flex-row justify-center pt-12 pb-3 lg:py-16 xl:py-36">
        <button
          class="flex items-center justify-center gap-2 text-center mt-2 px-8 py-[1.1rem] btn-primary text-sm font-semibold cursor-pointer w-full lg:w-fit"
          :disabled="!data.quicksearchProducts.length" @click="search">
          {{ trans('view_all') }}
        </button>
      </div>

    </div>

  </div>

</div>
</template>

<script setup>
import ProductContainer from "@/Shared/ProductContainer.vue";
import { useDevice } from "g/Composables/GlobalDevice";
import { placeholderUrl } from "@/Composables/Swap.js";
import { shallowRef, reactive, computed, watch, onBeforeMount, onBeforeUnmount, inject } from "vue";

const { device } = useDevice();

const { overlay, setOverlay, searchTerm, search } = inject('Nav', null);

const thisSearch = shallowRef(null);

const data = reactive({
  quicksearchProducts: [],
  timeout: false,
  loading: false,
  openedOnce: false,
});

onBeforeMount(() => {
  document.addEventListener("inertia:finish", hide);
});

onBeforeUnmount(() => {
  document.removeEventListener("inertia:finish", hide);
});

watch(() => searchTerm.value, (newValue) => {
  if (newValue) {
    debouncedQuickSearch();
  }
});

const limit = computed(() => {
  return device.isDesktop ? 12 : 8;
});

const debouncedQuickSearch = () => {

  clearTimeout(data.timeout);

  data.timeout = setTimeout(() => {
    // console.log("set timeout");
    quickSearch();
  }, 300);
};

const quickSearch = () => {

  // console.log("quickSearch");

  if (searchTerm.value < 2) {
    return false;
  }

  data.loading = true;

  window.axios
    .get("/search/products", {
      params: {
        page: 1,
        q: searchTerm.value,
        limit: limit.value,
      },
    })
    .then((response) => {

      data.loading = false;

      let paginator = response.data;
      if (paginator.products && paginator.products.data) {
        data.quicksearchProducts = paginator.products.data;
        setOverlay('search', true);
      }

      if (!data.openedOnce && data.quicksearchProducts.length) {
        data.openedOnce = true;
      }

    })
    .catch((error) => {
      console.error(error);
      data.loading = false;
    });

};

const closeSearch = () => {
   data.quicksearchProducts = [];
   data.openedOnce = false;
  // searchTerm.value = "";
};

const hide = () => overlay.search = null;

watch(() => overlay.search, (newValue) => {
  if (!newValue) {
    closeSearch();
  } else {
    setTimeout(() => {
      if (thisSearch.value) {
        thisSearch.value.focus();
      }
    }, 0);
  }
});

</script>
