<template>
<div class="max-w-screen relative">

  <div v-if="sliderType == 'product'">

    <div v-if="header?.title" class="relative flex items-center justify-between w-full h-full lg:mt-8 mb-2 lg:mb-10"
      :class="{ 'lg:px-12': extrapad }">

      <div class="flex flex-row items-end gap-5 sm:gap-10">
        <div class="flex gap-4 justify-start items-center">
          <div class="flex font-bold text-left text-2xl sm:text-3xl lg:text-5xl">
            {{ header.title }}
          </div>
        </div>
        <Link v-if="header?.link" class="cursor-pointer text-base w-fit font-bold hover:underline" :href="header.link">
          <span class="hidden sm:block"> {{ trans('view_all_products') }}</span>
          <span class="sm:hidden">{{ trans('view_all') }}</span>
        </Link>
      </div>

      <SliderArrows v-show="sliderAttrs?.navigation?.enabled" :unique-key="uniqueKey" />

    </div>

    <Slider class="lg:!pb-4" v-bind="sliderAttrs" :elements="elements">
      <template #slide="slide">
        <ProductContainer class="min-h-full" :product="slide.item" />
      </template>
    </Slider>

  </div>

  <div v-else-if="sliderType == 'slot'" :class="spaceBetween">

    <!-- HOX älkää laittako tänne jotain classejä -->
    <div class="relative flex items-center" :class="[{ 'lg:px-12': extrapad }]">
      <slot name="title" />

      <div v-show="sliderAttrs?.navigation?.enabled" class="ml-auto">
        <SliderArrows :unique-key="uniqueKey" />
      </div>

    </div>

    <Slider v-bind="sliderAttrs" :elements="elements">
      <template #slide="slide">
        <slot name="slide" :slide="slide"></slot>
      </template>
    </Slider>

  </div>

</div>
</template>

<script setup>
import { ref } from "vue";
import Slider from '@/Shared/Slider.vue';
import SliderArrows from '@/Shared/SliderArrows.vue';
import ProductContainer from '@/Shared/ProductContainer.vue';

const props = defineProps({
  elements: {
    type: [Object, Array],
    required: true,
  },
  uniqueKey: {
    type: String,
    required: true,
  },
  sliderType: {
    type: String,
    required: false,
    default: 'product',
  },
  sliderProps: {
    type: Object,
    required: false,
    default: () => { },
  },
  header: {
    type: Object,
    required: false,
    default: () => ({
      title: '',
      link: '',
    }),
  },
  extrapad: {
    type: Boolean,
    required: false,
    default: true,
  },
  spaceBetween: {
    type: String,
    required: false,
    default: 'space-y-0',
  },
  hide: {
    type: Object,
    required: false,
    default: () => { },
  },
});

if (typeof props.uniqueKey !== 'string') {
  console.error("CustomSlider -" + props.sliderType + "- uniqueKey is not a String");
}

const sliderAttrs = ref(Object.assign({},
  {
    mousewheel: { enabled: true, forceToAxis: true },
    freeMode: { enabled: true, sticky: false, momentumBounce: false },
  }, props.sliderProps));

if (!sliderAttrs.value?.navigation) {
  sliderAttrs.value.navigation = {
    nextEl: `.${props.uniqueKey}-next`,
    prevEl: `.${props.uniqueKey}-prev`,
    enabled: !props.hide?.navigation ? true : false,
  };
}

if (!sliderAttrs.value?.breakpoints) {
  sliderAttrs.value.breakpoints = {
    0: {
      slidesPerView: 1.5,
      slidesPerGroup: 2,
      spaceBetween: 10,
    },
    650: {
      slidesPerView: 3.5,
      slidesPerGroup: 4,
      spaceBetween: 10,
    },
    1200: {
      slidesPerView: 4.5,
      slidesPerGroup: 4,
      spaceBetween: 10,
    },
    1650: {
      slidesPerView: 5.5,
      slidesPerGroup: 5,
      spaceBetween: 15,
    },
  };
}

</script>
