import { ref, computed, watch } from "vue";
import { useStore } from "g/Composables/GlobalStore.js";
import { usePage } from "@inertiajs/vue3";

const store = useStore();

const $page = usePage();

const mainMenuItems = computed(() => {
  let items = store['group_tree'] ?? [];
  if (items.length == 1 && items?.[0]?.children?.length) {
    return items[0].children;
  }
  return items;
});

const previousItems = ref([]);

const selectedItem = ref(null);

const groupNumbers = ref(null);

const lastGroupNumber = ref(null);

watch(() => $page.props?.breadcrumbs, (newValue) => {
  const breadcrumbs = (newValue ?? []).map(item => item.number);
  groupNumbers.value = breadcrumbs;
  lastGroupNumber.value = breadcrumbs[breadcrumbs.length - 1] ?? null;
});

const setPath = () => {

  const loop = (b) => {

    let item = (b.number == lastGroupNumber.value) ? b : null;

    if (groupNumbers.value.includes(b.number)) {
      previousItems.value.push(b);
    }

    if (!item) {
      b?.children && b.children.forEach(loop);
    } else {
      selectedItem.value = item;
    }

  };

  let item = mainMenuItems.value.find((a) => a.target_type === 'product-group');

  if (item) {
    previousItems.value.push(item);
    item.children.forEach(loop);
  }

};

const back = () => {
  if (previousItems.value.length > 1) {
    previousItems.value.splice(-1, 1);
  }
  previousItems.value = previousItems.value.slice(-10); // prevents array from ever being larger than 10 items
  selectedItem.value = previousItems.value[previousItems.value.length - 1];
};

const useMobileMenu = () => {

  // resets menu

  previousItems.value = [];

  previousItems.value.push(
    {
      children: mainMenuItems.value,
      name: null,
      target_type: null,
      value: null,
      level: 0,
    });

  selectedItem.value = previousItems.value[0];

  // set path position if such exists
  // HERE: this is if you want position to open in current category

  // if (lastGroupNumber.value) {
  //   setPath();
  // }

  return {
    mainMenuItems,
    groupNumbers,
    previousItems,
    selectedItem,
    back,
  };
};

export {
  useMobileMenu,
};
