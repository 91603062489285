<template>
  <div class="flex flex-col items-center gap-2 w-full max-w-[96px]">
    <div class="flex items-center w-full">

      <!-- Minus button -->
      <span
        class="border-r-0 rounded-l-lg border border-cx1-primary text-center text-primary text-base font-normal p-0 flex items-center cursor-pointer group hover:bg-gray-50"
        :class="[sizeClasses.w, sizeClasses.h]" @click="buyAmount = Math.max(buyAmount - step, min)">
        <svg class="block w-full pl-2" width="5" height="3" viewBox="0 0 5 3" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path d="M0.398438 2.6875V0.734375H4.59375V2.6875H0.398438Z" fill="#B2B0B0" />
        </svg>
      </span>

      <!-- Amount input and unit -->
      <span class="flex item-center justify-center flex-grow border-cx1-primary border-t border-b focus:border-tertiary"
        :class="[sizeClasses.h]">
        <input v-model="buyAmount"
          class="max-w-[25px] border-x-0 text-base focus:underline focus:outline-none border-0 focus:ring-0 text-center text-primary font-bold p-0 flex items-center [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
          :min="min" :max="max" :step="step" :disabled="step != 1" type="number" pattern="[0-9]*">
        <!-- <span class="border-x-0 border-[#e5e7eb] text-center text-primary text-sm font-normal p-0 flex items-center">
          {{ unit }}
        </span> -->
      </span>

      <!-- Plus button -->
      <span
        class="border-l-0 rounded-r-lg border border-cx1-primary text-center text-primary text-base font-normal p-0 flex items-center group cursor-pointer hover:bg-gray-50"
        :class="[sizeClasses.w, sizeClasses.h]" @click="buyAmount = Math.min(buyAmount + step, max)">
        <svg class="block w-full pr-2" width="9" height="9" viewBox="0 0 9 9" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M5.34375 3.50781H8.36719V5.21875H5.34375V8.22656H3.63281V5.21875H0.609375V3.50781H3.63281V0.460938H5.34375V3.50781Z"
            fill="black" />
        </svg>
      </span>

    </div>
    <!-- TODO: Packaging size * item amount calculation -->
    <!-- <div class="text-xs text-center font-bold"> 40 kpl = 400 cm </div> -->
  </div>
</template>

<script setup>

import { computed, watch } from 'vue';

const props = defineProps({
  modelValue: {
    type: Number,
    required: true,
  },
  min: {
    type: Number,
    required: false,
    default: 1,
  },
  max: {
    type: Number,
    required: false,
    default: 999,
  },
  step: {
    type: Number,
    required: false,
    default: 1,
  },
  unit: {
    type: String,
    required: false,
    default: "kpl",
  },
  size: {
    type: String,
    default: 'large',
    validator(value) {
      return ['large', 'medium', 'small'].includes(value);
    },
  },
  height: {
    type: String,
    required:false,
    default: '',
  },
});

const emits = defineEmits(['update:modelValue']);

const buyAmount = computed({
  get() {
    return props.modelValue;
  },
  set(newValue) { // min max step validation happens here

    if (props.min && newValue <= props.min) {
      newValue = props.min;
    }
    else if (props.max && newValue >= props.max) {
      newValue = props.max;
    }

    emits('update:modelValue', newValue);
  },
});

const sizeClasses = computed(() => {
  const sizeOptions = {
    large: {
      h: 'h-14',
      w: 'w-full',
      iconWidth: 18,
    },
    medium: {
      h: 'h-[55px]',
      w: 'w-full',
      iconWidth: 12,
    },
    small: {
      h: 'h-6',
      w: 'w-full',
      iconWidth: 12,
    },
  };
  let size = sizeOptions[props.size] ?? sizeOptions['large'];
  if(props.height) {
    size.h = props.height;
  }
  return size;
});

watch(() => props.step, () => {
  if (buyAmount.value & props.step !== 0) {
    emits('update:modelValue', props.min);
  }
});

</script>
