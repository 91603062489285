<template>
<div class="flex flex-col flex-1 items-end mt-6 justify-end">

  <button v-if="!stockalertInput"
    class="w-full text-center py-3 px-3 btn-primary text-sm font-bold group flex justify-center"
    :class="{ 'opacity-40 hover:opacity-80': !product.isBuyable }" :disabled="loadingProductId === product.id"
    @click.prevent="action(product)">
    <div v-if="loadingProductId !== product.id">
      <span v-if="product.isParent && product.isBuyable">
        {{ trans('see_variations') }}
      </span>
      <span v-else-if="isCoaching">
        {{ trans('see_dates') }}
      </span>
      <span v-else-if="product.isBuyable">
        {{ trans('add_to_cart') }}
      </span>
      <span v-else-if="stockalert.showSuccess">
        {{ trans('notification_ordered') }}
      </span>
      <template v-else>
        <!-- {{ trans('request_quotation') }} -->
        <!-- {{ trans('temporairly_outofstock') }} -->
        <!-- <span class="">
            <span class="group-hover:hidden">
              {{ trans('temporairly_outofstock') }}
            </span>
            <span class="hidden group-hover:block">
              {{ trans('order_notification') }}
            </span>
          </span> -->
        <span class="hidden lg:block">{{ trans('order_stockalert_title') }}</span>
        <span class="lg:hidden">{{ trans('order_notification') }}</span>
      </template>
    </div>
    <RefreshIcon v-if="loadingProductId === product.id" class="h-5 w-5 animate-spin" />
  </button>

  <div v-if="stockalertInput" class="mt-auto w-full">
    <template v-if="!stockalert.showSuccess">
      <p class="text-base whitespace-nowrap">{{ trans('outofstock_order_notification') }}</p>
      <form class="flex flex-row justify-center" @submit.prevent="submitStockalert">
        <input v-model="stockalert.form.email" type="email" name="email" required=""
          :placeholder="trans('your_email_address')"
          class="border-0.5 lg:border-r-0 border-gray-300 py-2 lg:py-2 block w-full shadow-sm focus:ring-gray-800 focus:border-gray-8000 rounded-l-lg">
        <button type="submit" class="py-2 lg:py-2 px-4 btn-primary font-bold rounded-l-none"
          :disabled="stockalert.loading">
          <RefreshIcon v-if="stockalert.loading" class="h-5 w-5 animate-spin" />
          <span v-else class="uppercase tracking-[2px] text-sm">{{ trans('subscribe') }}</span>
        </button>
      </form>
    </template>
    <div v-if="stockalert.form.errors.email" class="flex justify-center text-red-400">{{
    stockalert.form.errors.email }}</div>
    <div v-if="stockalert.showSuccess"
      class="flex justify-center text-cx1-primary py-2.5 text-sm tracking-[2px] uppercase">
      {{
    trans('arrival_notification_ordered') }}</div>
  </div>
</div>

<Teleport to="body">
  <Modal v-if="showOfferForm" @hide="showOfferForm = false">
    <div class="mx-auto px-12 select-none">
      <contactForm :product="product" @hide="showOfferForm = false" />
    </div>
  </Modal>
</Teleport>
</template>


<script setup>
import * as Sentry from "@sentry/vue";
import Modal from "@/Shared/Modal.vue";
import contactForm from "@/Components/Product/contactForm.vue";
import { router } from '@inertiajs/vue3';
import RefreshIcon from "@/Icons/RefreshIcon.vue";
import { cartAdd } from 'g/Scripts/Functions.js';
import { useForm } from '@inertiajs/vue3';;
import { ref, onBeforeUnmount, onErrorCaptured } from "vue";

const props = defineProps({
  product: {
    type: Object,
    required: true,
  },
});

const isCoaching = props.product?.material_code?.trim() !== '' || props.product?.material?.trim() !== '';

const showOfferForm = ref(false);

const loadingProductId = ref(null);
const stockalertInput = ref(null);
const stockalert = ref({});

stockalert.value.form = useForm({ email: null, product: null });
stockalert.value.showSuccess = false;
stockalert.value.loading = false;

onErrorCaptured((err) => {
  console.log("Error in product container", err.message);
  Sentry.captureException(err);
  return false;
});

const addToCart = (productId) => {
  cartAdd(productId, {
    amount: 1,
    selections: [],
    onStart: () => {
      loadingProductId.value = productId;
    },
    onFinish: () => {
      loadingProductId.value = null;
    },
    onError: () => {
      console.log('failure');
    },
  });
};

const goToProduct = (link) => {
  router.visit(link);
};

var localTimeout;
const stockalertTimeout = () => {
  localTimeout = setTimeout(() => {
    stockalert.value.showSuccess = true;
    stockalert.value.loading = false;
    stockalertInput.value = false;
  }, 8000);
};

const action = (product) => {
  if (!product.isBuyable && false) { // show request quotation/offer form
    // stockalertInput.value = !stockalertInput.value;
    // if(stockalert.value.showSuccess) stockalertTimeout();
    showOfferForm.value = true;
  }
  else if (!product.isParent && product.isBuyable && !isCoaching) addToCart(product.id); // buy me
  else goToProduct(product.link); // if variation or nostock
};

const submitStockalert = () => {
  stockalert.value.product = props.product.id;

  if (stockalert.value.loading) {
    return false;
  }

  stockalert.value.form.clearErrors();
  stockalert.value.loading = true;

  stockalert.value.form.post('/product/stockalert',
    {
      preserveScroll: true,
      onSuccess: () => {
        stockalert.value.form.reset('email');
      },
      // onError: errors => { console.log(errors); },
      onFinish: () => {
        stockalertTimeout();
      },
    },
  );
};

onBeforeUnmount(() => {
  clearTimeout(localTimeout);
});

</script>
