<template>
<div class="flex gap-8">

  <!-- Toggles megamenu overlay -->
  <button class="-m-2 p-2 flex gap-2 items-center hover-effect"
    :class="{ 'bg-gray-50 !border-cx3-primary': activeItem?.id == 'tree' }"
    @mouseenter.prevent="toggle({ id: 'tree', children: groupTree })">
    <div class="flex justify-center items-center h-6 w-6" :class="{ 'fill-cx3-primary': activeItem?.id == 'tree' }">
      <HamburgerIcon />
    </div>
    <div class="hidden lg:block text-cx1-primary text-xl">{{ trans('product_groups') }}</div>
  </button>

  <!-- Regular menu items -->
  <div v-if="store['mainmenu']?.length" class="flex flex-wrap gap-4 2xl:gap-y-2 2xl:gap-x-8">

    <div v-for="item in store['mainmenu']" :key="item.id"
      class="flex items-center text-xl text-cx1-primary cursor-pointer p-2 -m-2 hover-effect"
      :class="{ '!bg-gray-50 !border-cx3-primary': activeItem?.id == item.id }" @mouseenter.prevent="toggle(item)"
      @mouseleave.prevent="(!overlay.megamenu) ? reset() : ''">

      <Link v-if="item.target_type === 'menu-link' && item.children.length == 0" :href="getLink(item)" class="">
      {{ item.name }}
      </Link>

      <div v-else class="">
        {{ item.name }}
      </div>

    </div>

  </div>

  <!-- Megamenu overlay items -->
  <div v-if="overlay.megamenu" class="absolute h-screen lg:h-auto top-0 lg:top-full inset-x-0 z-30">
    <div class="bg-white shadow" @mouseleave.prevent="reset()">

      <div class="flex relative max-w-default mx-auto px-2 lg:px-8 py-4 lg:py-12">

        <div class="flex flex-col gap-20 w-full h-full max-h-[80vh] overflow-y-auto">

          <div class="px-9 xl:px-8 grid grid-cols-3 xl:grid-cols-4 gap-12">

            <MegaMenuChunk v-if="activeChunks?.[0]" class="col-start-1 col-end-1" :chunk="activeChunks?.[0]" />

            <MegaMenuChunk v-if="activeChunks?.[1]" class="col-start-2 col-end-2" :chunk="activeChunks?.[1]" />

            <MegaMenuChunk v-if="activeChunks?.[2]" class="col-start-3 col-end-3" :chunk="activeChunks?.[2]" />

            <MegaMenuChunk v-if="activeChunks?.[3]" class="col-start-4 col-end-4" :chunk="activeChunks?.[3]" />

          </div>

          <div v-if="megamenuNosto" class="px-9 xl:px-8">
            <MegaMenuNosto :nosto="megamenuNosto" />
          </div>

        </div>
      </div>
    </div>
    <div class="z-30 bg-transparent bg-opacity-50 h-screen" @click.prevent="reset()"></div>
  </div>
</div>
</template>

<script setup>
import { ref, computed, inject, provide, watch } from "vue";
import { megamenuNosto } from "@/Composables/Shared/StaticPromos.js";
import HamburgerIcon from "@/Icons/HamburgerIcon.vue";
import MegaMenuChunk from "@/Components/Nav/MegaMenuChunk.vue";
import MegaMenuNosto from "@/Components/Nav/MegaMenuNosto.vue";

const { overlay, store } = inject('Nav', null);

const activeItem = ref(null);

const activeChunks = ref([]);

const SSR = import.meta.env.SSR;

const groupTree = computed(() => {
  let items = store['group_tree'] ?? [];
  if (items?.length == 1 && items?.[0]?.children?.length) {
    return items[0].children;
  }
  return items;
});

const columns = computed(() => {
  if (SSR) return 4;
  return (window?.innerWidth ?? 0) > 1280 ? 4 : 3; // XL
});

var chunkCache = {};
const getChunks = (item) => {

  const chunks = [];

  if (!item || !item.children?.length) return chunks;

  const cachedValue = chunkCache[item.id];

  if (cachedValue) {
    // console.log("from cache");
    return cachedValue;
  }

  var array = [...item.children];

  const chunkSize = array.length / columns.value;

  for (let i = 0; i < array.length; i += chunkSize) {
    const chunk = array.slice(i, i + chunkSize);
    chunks.push(chunk);
  }

  chunkCache[item.id] = chunks;

  return chunks;

};

var timeout;

const toggle = (item) => {

  clearTimeout(timeout);

  timeout = setTimeout(() => {

    activeItem.value = activeItem.value?.id == item?.id ? null : item;

    activeChunks.value = (activeItem.value && activeItem.value?.children?.length)
      ? activeChunks.value = getChunks(activeItem.value)
      : activeChunks.value = [];

  }, 25);

};

const reset = () => {
  overlay.megamenu = null;
  activeItem.value = null;
  activeChunks.value = [];
};

const getLink = (item) => item?.value ?? item?.link ?? '';

watch(() => activeChunks.value, (newValue) => {
  overlay.megamenu = Array.isArray(newValue) && newValue.length
    ? true
    : null;
});

watch(() => overlay.megamenu, (newValue) => {
 if(!newValue && activeItem.value) reset();
});

provide('MegaMenu', {
  getLink,
});

</script>
<style scoped>
.hover-effect {
  @apply border-b-2 border-transparent hover:border-cx3-primary active:border-cx3-primary
}
</style>
