<template>

  <div class="flex flex-col lg:flex-row justify-between gap-6 flex-wrap">
    <FooterNode v-for="item in store['footer']" :key="item.id" :item="item" />
    <!-- <NewsletterBlock /> -->
    <div class="flex gap-8">
      <SomeLinks/>
    </div>
  </div>

</template>

<script setup>
import { useStore } from "g/Composables/GlobalStore.js";
import FooterNode from "@/Components/Menu/FooterNode.vue";
// import NewsletterBlock from "@/Components/Newsletter/NewsletterBlock.vue";
import SomeLinks from "@/Shared/SomeLinks.vue";
import { onErrorCaptured } from "vue";

const store = useStore();

onErrorCaptured((err) => {
  console.error(err);
  return false;
});

</script>
