import { mergeDeep } from "g/Scripts/Functions.js";
import { unref, shallowReactive, inject } from "vue";

export default function useContainerProperties() {

  const type = unref(inject('listType', 'grid'));

  const injects = inject('ProductContainer', {
    list: {},
    grid: {},
  });

  const gridStyle = {
    hide: {
      colors: false,
      buyButton: false,
      reviews: false,
    },
    imgHeight: 'h-[231px] lg:h-[245px]',
    nameStyle: 'mt-1 lg:mt-5 text-sm lg:text-xl',
    descriptionStyle: 'text-base',
    priceStyle: 'text-sm lg:text-lg',
    wishlistButtonStyle: 'right-5 bottom-1 py-3',
  };

  const defaults = shallowReactive({
    grid: gridStyle,
  });


  let obj = injects[type];
  if (obj && Object.keys(obj).length) {
    mergeDeep(defaults[type], obj); // merge
  }

  const handler = { // returns empty string if defaults key does not exist
    get(target, prop) {
      return target[prop] ? target[prop] : '';
    },
  };

  const properties = new Proxy(defaults?.[type] ?? defaults, handler);

  return properties;


}
