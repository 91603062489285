<template>
  <div class="text-left text-cx1-primary">
    <div class="flex">
      <h1 class="text-xl font-black uppercase mb-12 text-cx1-primary">{{ trans('request_quotation') }}</h1>
      <div class="font-bold right-[24px] top-[34px] absolute cursor-pointer" @click="emits('hide', true)">
        <svg class="absolute right-0 top-0 bottom-0 m-auto" width="30" height="30" viewBox="0 0 34 34" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <rect width="34" height="34" fill="white" fill-opacity="0.01" />
          <path d="M14 14L34 34" stroke="#333" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M14 34L34 14" stroke="#333" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </div>
    </div>
    <div class="mb-8">
      {{ trans('contact_form_text') }}
    </div>
    <div>
      {{ trans('product') }}: <span class="font-bold text-cx1-primary">{{ product.name }}</span>
    </div>
    <div class="mb-8">
      {{ trans('prodnr') }}: <span class="font-bold text-cx1-primary">{{ product.number }}</span>
    </div>
    <form v-if="!postForm" class="flex flex-col gap-8" @submit.prevent="submit">
      <div>
        <label for="name" class="block text-sm font-medium required">{{ trans('name') }}</label>
        <div class="mt-1">
          <input id="name" v-model="form.name" type="text" name="name" autocomplete="name" required
            class="py-3 px-4 block w-full shadow-sm focus:ring-gray-800 focus:border-gray-800 border-gray-300 rounded-lg" />
          <div v-if="form.errors.name" class="text-sm text-red-400">{{ form.errors.name }}</div>
        </div>
      </div>
      <div>
        <label for="email" class="block text-sm font-medium required">{{ trans('email') }}</label>
        <div class="mt-1">
          <input id="email" v-model="form.email" type="text" name="email" autocomplete="email" required
            class="py-3 px-4 block w-full shadow-sm focus:ring-gray-800 focus:border-gray-800 border-gray-300 rounded-lg" />
          <div v-if="form.errors.email" class="text-sm text-red-400">{{ form.errors.email }}</div>
        </div>
      </div>
      <div>
        <label for="message" class="block text-sm font-medium required">{{ trans('message') }}</label>
        <div class="mt-1">
          <textarea id="message" v-model="form.message" rows="5" name="message" required
            class="py-3 px-4 block w-full shadow-sm focus:ring-gray-800 focus:border-gray-800 border-gray-300 rounded-lg"></textarea>
          <div v-if="form.errors.message" class="text-sm text-red-400">{{ form.errors.message }}</div>
        </div>
      </div>
      <button type="submit" :disabled="form.processing"
        class="btn-primary min-w-[165px] max-w-xs text-center mt-2 sm:mt-8 py-2 lg:py-3 px-8 ml-auto font-semibold flex items-center justify-center"
        @click="submit">
        <span v-if="!form.processing">{{ trans('send_message') }}</span>
        <RefreshIcon v-else class="h-5 w-5 animate-spin" />
      </button>
    </form>
    <div v-if="postForm" class="font-bold">
      {{ trans('form_received') }}
    </div>
  </div>
</template>

<script setup>
import RefreshIcon from "@/Icons/RefreshIcon.vue";
import { useForm } from "@inertiajs/vue3";
import { ref } from 'vue';

var postForm = ref(false);

const props = defineProps({
  product: {
    type: Object,
    required: true,
  },
});

const emits = defineEmits(['hide']);

const form = useForm({
  name: null,
  email: null,
  message: null,
  product_name: props.product.name,
  product_number: props.product.number,
  product_link: props.product.fullLink,
});

const submit = () => {
  form.post('/page/contact', {
    preserveScroll: true,
    onSuccess: () => {
      sessionStorage.setItem('contactForm', true);
      postForm.value = true;
      // emits('hide')
    },
  });
};

</script>
