<template>
<div>
  <div class="group relative w-full py-2 flex justify-between items-center text-left" :aria-expanded="isOpen">

    <div class="w-full flex" @click="toggleOpen">
      <slot name="title" />
    </div>

    <div class="space-x-4">
      <div v-if="showIcon" class="flex flex-1 items-center cursor-pointer" :class="{ 'justify-end': justifyEnd }"
        @click="toggleOpen">
        <ChevronUpIcon v-if="isOpen" class=" h-6 w-6 text-cx1-primary hover:text-cx1-hover" />
        <ChevronDownIcon v-else class=" h-6 w-6 text-cx1-primary hover:text-cx1-hover" />
      </div>
      <slot name="custom-icon" :open="isOpen" />
    </div>

  </div>

  <div v-if="isOpen">
    <slot />
  </div>

</div>
</template>

<script setup>
import { ref } from "vue";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/vue/solid/index.js";

const props = defineProps({
  title: {
    type: String,
    required: false,
    default: undefined,
  },
  url: {
    type: String,
    required: false,
    default: undefined,
  },
  open: {
    type: Boolean,
    required: false,
    default: false,
  },
  showIcon: {
    type: Boolean,
    required: false,
    default: true,
  },
  justifyEnd: {
    type: Boolean,
    required: false,
    default: true,
  },
});

const isOpen = ref(props.open);

const toggleOpen = () => {
  isOpen.value = !isOpen.value;
};

</script>
