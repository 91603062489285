<template>
  <div class="flex flex-col bg-white rounded-lg hover:shadow-lg">
  
    <Link :href="product.link" class="flex flex-col group text-sm relative group">
  
    <!-- <h3 class="font-bold text-cx1-primary text-sm">{{ product.brandName }}</h3> -->
  
    <!--  -->
    <div class="w-full aspect-w-1 aspect-h-1 overflow-hidden relative my-2 group-hover:opacity-75 rounded-lg"
      @mouseover="hover = true" @mouseleave="hover = false">
      <!--
            :class="{ 'opacity-40': !product.isBuyable, 'hover:opacity-75': product.isBuyable }
          -->
      <!-- product.main_picture.pictureThumbUrl -->
  
      <img v-if="variationPicture" :src="variationPicture.pictureThumbUrl" :alt="product.name"
        class="w-full object-center object-contain" :class="[container.imgHeight]" @error.once="swap" />
      <template v-else-if="product.main_picture">
        <img v-show="!hover || product.pictures.length < 2" :src="product.pictures[0].pictureThumbUrl" :alt="product.name"
          class="w-full object-center object-contain" :class="[container.imgHeight]" @error.once="swap" />
        <template v-if="product.pictures.length > 1">
          <img v-show="hover" loading="lazy" :src="product.pictures[1].pictureThumbUrl" :alt="product.name"
            class="w-full object-center object-contain" :class="[container.imgHeight]" @error.once="swap" />
        </template>
      </template>
      <img v-else :src="placeholderUrl" alt="logo" :class="container.imgHeight"
        class="w-full object-center object-scale-down rounded-lg px-4" />
  
      <!-- peekaboo section -->
      <div v-if="product.variationNames && product.variationNames.length && !disable">
        <div
          class="absolute left-0 right-0 group-hover:-bottom-2 px-2 py-4 h-0 group-hover:h-fit transition-height duration-300 ease-in-out overflow-y-auto max-h-[200px]">
          <div class="max-w-[85%]">
            <VariationNames :data="product.variationNames" />
          </div>
  
        </div>
      </div>
  
      <div class="absolute" :class="container.wishlistButtonStyle">
        <WishlistButton :product="product" @disableEffects="disable = $event" />
      </div>
  
      <div class="absolute top-2 left-0">
        <ProductTags :product="product" :container="true"  :useInjectedDiscount="false" />
      </div>
  
    </div>
  
    <div class="flex flex-col px-2 lg:px-3">
  
      <!-- Product name desktop -->
      <h3 class="break-words group-hover:underline max-w-[340px] font-bold text-cx1-primary" :class="container.nameStyle">
        {{ product.name }}</h3>
  
      <div v-if="product?.tags && product.tags.length" class="flex gap-2 mt-3 lg:mt-4 mb-1"
        :class="container.descriptionStyle">
        <span v-for="(tag, index) in product.tags" :key="index" class="flex gap-2">
          <span>{{ tag.name }}</span>
          <span v-if="index !== product.tags.length - 1" class="">|</span>
        </span>
      </div>
  
  
      <!-- <div v-if="product.description" class="line-clamp-2 mb-6 text-base text-cx1-primary">
          {{ strip_tags(product.description) }}
        </div> -->
  
      <!-- <div v-if="product.saldotexts && product.saldotexts['Verkkokauppa'] && false" class="flex gap-2 items-center mt-2">
          <span class="rounded-full flex-shrink-0 w-3 h-3 border border-gray-300"
            :style="{ 'background-color': product.saldotexts['Verkkokauppa'].color }">
          </span>
          <span>{{ product.saldotexts['Verkkokauppa'].stock_text }}</span>
        </div>
  
        <div v-if="product?.updated_at">{{ product.updated_at }}</div> -->
  
      <!-- <div v-if="product.variationNames && product.variationNames.length" class="hidden lg:block">
          <div class="absolute left-0 right-0 -bottom-2 bg-white p-2 h-0 group-hover:h-16 transition-height duration-500 ease-in-out">
            <VariationNames :data="product.variationNames"/>
          </div>
        </div> -->
  
      <slot :product="product"></slot>
    </div>
    </Link>
  
    <div class="flex flex-col px-2 lg:px-3 mt-auto">
  
      <div class="xl:flex xl:flex-row justify-between flex-grow">
        <div class="flex my-3 lg:my-4 flex-1 lg:items-end">
          <ProductDisplayPrice :price="product.display_price" :price-data="product.price_data" :component="'Container'"
            class="font-semibold" :class="container.priceStyle" />
        </div>
      </div>
  
      <template v-if="product.variationNames &&
      product.variationNames.length &&
      !container.hide.colors
      ">
        <VariationColors :product="product" @update:variationPicture="variationPicture = $event" />
      </template>
  
      <!-- Stars desktop -->
      <div v-if="product?.reviews && !container.hide.reviews" class="flex gap-2 items-center">
        <ProductStars :stars="product.reviews.average" />
        <div class="text-cx4-primary text-sm">({{ product.reviews.totalCount || 0 }})</div>
      </div>
  
      <div class="px-0 pb-3">
        <ContainerBuyButton v-if="!container.hide.buyButton" :product="product" />
      </div>
  
    </div>
  
  </div>
  </template>
  
  
  <script setup>
  import * as Sentry from "@sentry/vue";
  import { placeholderUrl, swap } from "@/Composables/Swap.js";
  import { shallowRef, onErrorCaptured } from "vue";
  import WishlistButton from '@/Shared/WishlistButton.vue';
  import ProductDisplayPrice from "@/Shared/ProductDisplayPrice.vue";
  import VariationNames from '@/Shared/VariationNames.vue';
  import VariationColors from '@/Shared/VariationColors.vue';
  import ProductTags from "@/Shared/ProductTags.vue";
  import useContainerProperties from "@/Composables/ProductContainer.js";
  import ProductStars from "@/Shared/ProductStars.vue";
  import ContainerBuyButton from "@/Shared/ContainerBuyButton.vue";
  
  const container = useContainerProperties();
  
  defineProps({
    product: {
      type: Object,
      required: true,
    },
    stars: {
      type: Number,
      required: false,
      default: undefined,
    },
  });
  
  const hover = shallowRef(null);
  
  const variationPicture = shallowRef(null);
  
  const disable = shallowRef(null);
  
  onErrorCaptured((err) => {
    console.log("Error in product container", err.message);
    Sentry.captureException(err);
    return false;
  });
  
  </script>
  
