<template>
<div class="flex flex-col gap-1.5">
  <div v-if="selectedItem && selectedItem?.level > 0" class="w-full border-b border-cx4-primary">
    <div class="flex flex-col gap-1.5">
      <div class="">
        <div class="py-2.5 text-cx1-primary flex justify-between gap-2.5 items-center" @click="back()">
          <!-- <span class="text-lg font-medium">{{ trans('back') }}</span> -->
          <span class="text-xl font-bold">{{ selectedItem?.name ?? trans('back') }}</span>
          <ChevronLeftIcon class="w-5 h-5 lg:h-6 lg:w-6 text-black" />
        </div>
      </div>
      <div class="flex flex-col gap-2.5 pb-4 text-sm underline text-cx1-primary">
        <Link :href="selectedItem?.value ?? selectedItem.link" class="">
        {{ trans('show_all_category_products') }}
        </Link>
      </div>
    </div>
  </div>
  <div class="flex flex-col gap-1.5 pb-5">
    <div v-for="(item) in selectedItem.children" :key="item.id" class="flex flex-col">
      <MobileGroupTreeItems :item="item" />
    </div>
  </div>
</div>
</template>

<script setup>
import { provide } from "vue";
import { ChevronLeftIcon } from "@heroicons/vue/solid/index.js";
import { useTrans } from "g/Composables/Translations.js";
import { useMobileMenu } from "@/Composables/Nav/MobileGroupTree.js";
import MobileGroupTreeItems from "@/Shared/MobileGroupTreeItems.vue";

const trans = useTrans();

const { selectedItem, previousItems, back } = useMobileMenu();

provide('MobileGroupTree', {
  previousItems,
  selectedItem,
});

</script>
