<template>
<Transition v-if="cart && Object.keys((cart?.cartitems ?? {})).length" enter-from-class="opacity-0"
  leave-to-class="opacity-0" enter-active-class="transition duration-300" leave-active-class="transition duration-300">
  <div v-if="visible" class="z-50 fixed inset-0 transition-opacity">
    <div class="absolute inset-0 bg-black opacity-50" tabindex="0" @click="hide"></div>
  </div>
</Transition>

<Transition v-if="cart && Object.keys((cart?.cartitems ?? {})).length" enter-from-class="translate-x-full opacity-0"
  leave-to-class="translate-x-full opacity-0" enter-active-class="transition duration-300"
  leave-active-class="transition duration-300">
  <aside v-if="visible"
    class="transform top-0 right-0 w-screen lg:max-w-[536px] bg-white fixed h-full overflow-auto z-50 flex flex-col shadow-xl overflow-y-hidden">

    <div class="flex flex-col h-full py-6 overflow-y-auto px-4 sm:px-6">

      <div class="flex items-start justify-between">
        <div class="ml-3 h-7 flex justify-end items-center relative w-full">
          <button type="button"
            class="-m-2 p-2 text-black hover:text-gray-500 group flex items-center text-lg font-bold" @click="hide">
            X
          </button>
        </div>
      </div>

      <div class="flex flex-col flex-grow mt-4 overflow-x-hidden pr-2 min-h-[150px] ">
        <div class="flow-root">
          <ul role="list" class="">

            <li v-for="(item) in cart.cartitems" :key="item.id" class="flex py-1 bg-white">
              <div class="flex-shrink-0">
                <img v-if="item.picture" :src="item.picture" alt="image"
                  class="w-[123px] h-[116px] object-center object-contain rounded-lg" @error.once="swap" />
                <img v-else :src="placeholderUrl" alt="image"
                  class="w-[123px] h-[116px] object-center object-contain rounded-lg" />
              </div>

              <div class="ml-4 flex-1 flex flex-col sm:ml-6">
                <div class="flex flex-row items-start justify-between">
                  <div>
                    <h3 class="text-cx1-primary flex flex-col items-center max-w-[300px]">
                      <Link :href="item.link" class="font-bold text-sm lg:text-base"> {{ item.name }} </Link>
                      <div v-if="item.children && item.children.length" class="mt-4 mb-4 text-sm font-medium">
                        <div v-for="child in item.children" :key="child.id">
                          <!-- &hybull; -->
                          <!-- <span v-if="child.amount_cart > 1"> -->
                          {{ child.amount_cart }} {{ child.unit }}
                          <!-- </span> -->
                          {{ child.name }}
                        </div>
                      </div>
                    </h3>
                    <div class="text-sm flex flex-col max-w-[600px] pt-1">
                      <!-- <span class="line-clamp-2" v-text="item.description"></span> -->
                      <ItemsAttributes :item="item" />
                    </div>
                  </div>
                </div>


                <div class="mt-auto flex items-center justify-between gap-4">
                  <div class="w-full">
                    <ProductDisplayPrice :price="item.displayPrice" :price-data="item.price_data" :component="'Cart'"
                      :show-vat-percent="false" class="font-bold text-base" />
                  </div>

                  <div class="w-full flex flex-row-reverse items-center max-w-[76px] ">
                    <AmountButton v-if="item.row_type == 1" v-model="item.amount_cart" size="medium" height="h-10"
                      :min="item.saleLimits.min" :max="item.saleLimits.max" :step="item.saleLimits.step"
                      :unit="item.unit" @update:modelValue="(newAmount) => updateAmount(newAmount, item.id)
  " />
                    <span v-else class="flex items-center justify-end flex-grow border-cx1-primary font-bold">
                      {{ item.amount_cart }}&nbsp;{{ trans('pcs') }}
                    </span>
                  </div>

                  <div class="">
                    <button type="button" class="p-2 inline-flex text-cx4-primary hover:text-cx1-primary"
                      @click="removeItem(item.id)">
                      <TrashIcon class="h-5 w-5" />
                    </button>
                  </div>

                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="border-t border-b border-cx1-primary py-4 mt-5">
        <div class="flex justify-between text-base font-medium text-cx1-primary">
          <span class="text-lg font-bold">{{ trans('in_total') }}</span>
        </div>

        <div class="flex flex-col gap-2 mt-2 text-sm">
          <div class="text-[#5F5E5E] flex justify-between items-center">
            <span> {{ trans('shopping') }}</span>
            <span class="text-cx1-primary"> {{ cart.display_price.total_price_product.total_price_with_vat }}</span>
          </div>
          <div class="text-[#5F5E5E] flex justify-between items-center">
            <span class="text-[#5F5E5E]">{{ trans('shipping_added_in_cart') }}</span>
            <!-- <span class="text-cx1-primary"> {{ '+' }}</span> -->
          </div>
        </div>
      </div>

      <!-- HOX hidden until works with reship -->
      <div v-if="parseShippingCost != null && false" class="text-sm flex flex-col text-cx3-primary my-5 text-center">
        <span v-if="parseShippingCost > 0">
          <span>
            {{ trans('delta_to_free_shipping_text', { 'amount': deltaToFreeShippingCost }) }}
          </span>
        </span>
        <span v-else>
          {{ trans('free_delivery_active') }}
        </span>
      </div>
      <div v-else class="my-5"></div>

      <div class="flex flex-col gap-2">

        <div
          class="btn-secondary text-sm text-cx1-primary flex justify-center items-center px-6 py-3.5 font-semibold border border-cx1-primary"
          @click="hide">
          {{ trans('keep_shopping') }}
        </div>
        <Link href="/cart"
          class="btn-primary text-sm text-white flex justify-center items-center px-6 py-3.5 font-semibold border border-cx1-primary">
        {{ trans('go_to_cart') }}
        </Link>

      </div>

      <CartSidemenuAjaxSection />

    </div>

  </aside>
</Transition>
</template>

<script setup>
import ProductDisplayPrice from "@/Shared/ProductDisplayPrice.vue";
import CartSidemenuAjaxSection from "@/Shared/CartSidemenuAjaxSection.vue";
import ItemsAttributes from "@/Components/Cart/ItemsAttributes.vue";
import AmountButton from "@/Shared/AmountButton.vue";
import TrashIcon from "@/Icons/TrashIcon.vue";
import { placeholderUrl, swap } from "@/Composables/Swap.js";
// import { useDevice } from "g/Composables/GlobalDevice.js";
import { cartUpdateAmount } from "g/Scripts/Functions.js";
import { useCart } from "g/Store/GlobalUseCart.js";
import { ref, computed, onMounted, provide } from "vue";

defineProps({
  visible: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const cart = useCart();

// const { device } = useDevice();

const buyAlsoProducts = ref([]);

const deltaToFreeShippingCost = ref(null);

provide('ajaxSection', {
  buyAlsoProducts,
  deltaToFreeShippingCost,
});

provide('ProductContainer', {
  grid: {
    hide: {
      colors: true,
      buyButton: true,
      reviews: true,
    },
    imgHeight: 'h-[116px]',
    nameStyle: 'text-sm lg:text-base line-clamp-3',
    descriptionStyle: 'text-xs',
    priceStyle: 'text-sm',
    reviewsStyle: 'text-sm',
    wishlistButtonStyle: 'right-2 bottom-2',
  },
});

const emits = defineEmits(["hide", "loginHide"]);

// const open = ref(null);

const parseShippingCost = computed(() => {
  if (deltaToFreeShippingCost.value) {
    let cost = parseInt(deltaToFreeShippingCost.value.replace(/[^0-9]/g, ''));
    return parseInt(cost);
  }
  return null;
});

onMounted(() => {
  if (import.meta.env.SSR == false) {
    document.addEventListener('inertia:start', (event) => {
      if (event.detail.visit.url.pathname !== '/cart/update') {
        emits("hide", true);
      }
      if (event.detail.visit.url.pathname === '/login/attempt') {
        emits("loginHide", true);
      }
    });
  }
});

const hide = () => {
  emits("hide", true);
};

const update = (id, amount) => {
  // console.log("update", id, amount);
  cartUpdateAmount(id, amount, {
    onStart: () => {
      // console.log(" update start");
    },
    onSuccess: () => {
      // console.log(" update success");
    },
    onError: (err) => {
      console.error(err);
    },
    onFinish: () => {
      // console.log(" update finish");
    },
  });
};

const updateAmount = (event, id) => {
  let amount = typeof event === "number" ? event : event?.target?.value || 0;
  if (amount) {
    update(id, amount);
  }
};

const removeItem = (id) => {
  update(id, 0);
};

</script>
