<template>
  <Transition v-if="open"
    leave-to-class="opacity-0"
    enter-active-class="transition-opacity duration-800 ease-in-out"
    leave-active-class="transition-opacity duration-800 ease-in-out"
  >
  <div>
    <!-- <div v-if="open" class="z-10 fixed inset-0 transition-opacity">
      <div class="absolute inset-0 bg-black opacity-50" @click="close"></div>
    </div> -->
    <div v-if="open" @click="close" >
      <div class="flex flex-col absolute left-0 top-10 min-w-[200px] z-50" >
        <div class="h-0 w-0 ml-3 border-x-8 border-x-transparent border-b-[16px] border-b-gray-200"></div>
        <div class="p-4 bg-gray-200 text-sm rounded">
          <slot />
        </div>
      </div>
    </div>
  </div>
  </Transition>
</template>

<script setup>

import { watch } from "vue";

const props = defineProps({
  open: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits(['close']);

const close = () => emit('close');

let closeTimeout = null;
watch(() => props.open, () => {
  clearTimeout(closeTimeout);
  closeTimeout = setTimeout(close, 8_000);
});

</script>
