import { useStore } from "g/Composables/GlobalStore.js";
import { computed } from "vue";

const store = useStore();

const newsletterNosto = computed(() => Object.values(store['promos'])
  .filter((item) => item.order == 11)[0] ?? null,
);

const megamenuNosto = computed(() => Object.values(store['promos'])
  .filter((item) => item.order == 12)[0] ?? null,
);

export {
  newsletterNosto,
  megamenuNosto,
};
