<template>
<Transition enter-from-class="opacity-0" leave-to-class="opacity-0" enter-active-class="transition duration-300"
  leave-active-class="transition duration-300">
  <div v-if="overlay.mobile" class="fixed transition-opacity z-0">
    <div class="absolute w-screen h-screen bg-black opacity-50" tabindex="1" @click="overlay.mobile = null"></div>
  </div>
</Transition>


<!-- Sliding sidemenu  -->
<Transition enter-from-class="-translate-x-full opacity-0" leave-to-class="-translate-x-full opacity-0"
  enter-active-class="transition duration-300" leave-active-class="transition duration-300">


  <!--  -->
  <aside v-if="overlay.mobile"
    class="transform top-[70px] lg:top-auto left-0 w-screen lg:max-w-[536px] fixed h-full overflow-auto z-50 flex flex-col shadow-xl overflow-y-hidden" :style="`padding-bottom: ${navHeight}px;`">

    <div class="flex flex-col h-full overflow-y-auto bg-white">

      <!-- Header -->

      <!--
        <div class="px-4 py-5 flex items-center justify-between bg-white">
          <img :src="placeholderUrl" alt="logo" />
          <button type="button" class="p-2 rounded-lg inline-flex items-center justify-center text-cx1-primary"
            @click="open = false">
            <XIcon class="h-8 w-8" />
          </button>
        </div>
        -->

      <div class="flex flex-col flex-grow my-4 overflow-x-hidden px-6 pr-3 mr-3">
        <div class="flow-root">
          <MobileGroupTree />
        </div>
      </div>

      <div class="bg-black pb-4">

        <div class="flex flex-col pl-6 mt-4 gap-8 pr-3 mr-3 max-h-[300px] overflow-y-auto">

          <!-- Infomenu -->
          <div>
            <div v-if="store['mainmenu']?.length">
              <MobileToggle v-for="item in store['mainmenu']" :key="item.id" :item="item"
                :has-content="!!(item.children && item.children?.length)" />
            </div>
          </div>

          <div class="lg:hidden">
            <NavCountrySelect :force-open="true" />
          </div>

        </div>

      </div>

    </div>

  </aside>

</Transition>
</template>

<script setup>
import { inject } from "vue";
// import CategorySidemenuItem from "@/Shared/CategorySidemenuItem.vue";
// import MobileToggle from "@/Shared/MobileToggle.vue";
import MobileGroupTree from "@/Shared/MobileGroupTree.vue";
import NavCountrySelect from "@/Shared/NavCountrySelect.vue";
import MobileToggle from "@/Shared/MobileToggle.vue";

const { overlay, store, navHeight } = inject('Nav', null);

</script>
