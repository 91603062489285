<template>

  <MetaTags :metadata="metadata"/>
 
  <div class="flex flex-col min-h-screen justify-between">

    <Nav />

    <div class="bg-white flex-1">
      <div class="mt-0">
        <!-- page content -->
        <slot />
      </div>
    </div>

    <Footer />
  </div>

  <!-- consent is from usercentrics -->
  <!-- <div>
    <CookieConsent v-if="showCookieConsent" :style="3" @close="cookieModalClose"/>
  </div> -->

</template>


<script setup>
import { ref } from "vue";
import { useAnalytics } from "g/Composables/Analytics.js";
import { refreshWishlist } from "g/Composables/Wishlist.js";
import Nav from "@/Shared/Nav.vue";
import Footer from "@/Shared/Footer.vue";
// import CookieConsent from "@/Shared/CookieConsent.vue";
import MetaTags from "@/Components/Layout/MetaTags.vue";

defineProps({
  metadata: {
    type: Object,
    required: false,
    default: () => {},
  },
});

const showCookieConsent = ref(false);
if(import.meta.env.SSR !== true && window.cookieConsent === null) {
  showCookieConsent.value = true;
}

const cookieModalClose = (accept) => {
  showCookieConsent.value = false;
  localStorage.setItem('cookieConsent', accept);
  if(typeof fbq === 'function') {
    window.fbq('consent', accept ? "grant" : "revoke");
  }
  if(typeof gtag === 'function') {
    window.gtag('consent', 'update', { 'ad_storage': accept ? "granted" : "denied" });
  }
};

if (import.meta.env.SSR === false) {
  localStorage.setItem("cookieConsent", true);
  if (typeof fbq === "function") {
    window.fbq("consent","grant");
  }
  if (typeof gtag === "function") {
    window.gtag("consent", "update", {
      ad_user_data: "granted",
      ad_personalization: "granted",
      ad_storage: "granted",
      analytics_storage: 'granted',
      ads_data_redaction: 'false',
    });
    window.dataLayer.push({
    'event': 'consent_available',
    'ad_user_data': "granted",
    'ad_personalization': "granted",
    'ad_storage': "granted",
    'analytics_storage': "granted",
    'ads_data_redaction': 'false',
  });
  }
}



useAnalytics();

refreshWishlist();

</script>
