<!-- eslint-disable vue/no-v-html -->
<template>
  <div>

    <h2 v-if="item.children.length" class="text-xl font-bold uppercase mb-4"> {{item.name}} </h2>

    <div v-else-if="item.target_type === 'menu-text'">
      <h2 v-if="item.children.length === 0" class="text-xl font-bold uppercase mb-4" v-html="item.name"></h2>
      <div class="mb-2" :href="item.link" v-html="item.value"></div>
    </div>

    <a v-else-if="item.target_type === 'menu-link'" class="text-base block mb-2" :href="item.value"> {{item.name}} </a>

    <FooterNode v-for="child in item.children" :key="child.id" :item="child" />

  </div>
</template>

<script>
// TODO: Catch errors
export default {
  name: "FooterNode",
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>
