<template>
<div>

  <div class="group relative w-full py-2.5 flex justify-between items-center text-left lg:text-lg" :aria-expanded="isOpen">

    <div class="text-white" :class="{ 'font-bold': level == 0 }">
      <div v-if="item?.children && item?.children.length" @click="toggleOpen">{{ item.name }}</div>
      <Link v-else :href="item?.value ?? item?.link">{{ item.name }}</Link>
    </div>

    <div v-if="hasContent" class="flex flex-1 items-center justify-end cursor-pointer" @click="toggleOpen">
      <MinusIcon v-if="isOpen" class="shrink-0 h-4 w-4 lg:h-5 lg:w-5 text-white" />
      <PlusIcon v-else class="shrink-0 h-4 w-4 lg:h-5 lg:w-5 text-white" />
    </div>

  </div>

  <div v-if="isOpen">

    <template v-if="item.children && item.children?.length">
      <MobileToggle v-for="child in item.children" :key="child?.id ?? child?.number" class="pl-6" :item="child"
        :has-content="!!(child.children && child.children?.length)" :level="level + 1" />
    </template>

  </div>

</div>
</template>

<script setup>
import { shallowRef } from "vue";
import { MinusIcon, PlusIcon } from '@heroicons/vue/solid/index.js';

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
  hasContent: {
    type: Boolean,
    required: true,
    default: false,
  },
  open: {
    type: Boolean,
    required: false,
    default: false,
  },
  level: {
    type: Number,
    required: false,
    default: 0,
  },
});

const isOpen = shallowRef(props.open);

const toggleOpen = () => {
  isOpen.value = !isOpen.value;
};

</script>
