import { router } from "@inertiajs/vue3";
import { shallowRef, shallowReactive, watch, onMounted } from "vue";

const isLoaded = shallowRef(null);

const overlayObject = shallowReactive({
  cart: null,
  mobile: null,
  filter: null,
  mobileFilter: null,
  megamenu: null,
  search: null,
  mobileSearch: null,
});

const handler = {
  get(target, prop) {
    return target[prop] ? target[prop] : false;
  },
};

const overlay = new Proxy(overlayObject, handler);

const setOverlay = (key, bool = null, toggle = null, skip = []) => {
  let local = {};  // reset all
  for (const [key2] of Object.entries(overlayObject)) {
    if (Array.isArray(skip) && skip.includes(key2)) continue;
    local[key2] = null;
  }
  if (key !== 'reset') {
    local[key] = (toggle) ? !bool : bool;
  }
  if (Object.keys(local).length) {
    Object.assign(overlayObject, local);
  }
};

const allFalse = (newValue) => Object.values(newValue).every((e) => !e);

const handleEsc = (e) => {
  if (!e || allFalse(overlayObject)) return;
  var isEscape = false;
  if ("key" in e) {
    isEscape = (e.key === "Escape" || e.key === "Esc");
  } else {
    isEscape = (e.keyCode === 27);
  }
  if (isEscape) {
    setOverlay('reset');
  }
};

export const useOverlay = () => {

  if (!isLoaded.value) {

    isLoaded.value = true;

    onMounted(() => {
      // console.log('init');

      router.on("start", (event) => {
        const url = event.detail.visit.url;
        const pathname = url.pathname;
        if (
          !['/cart/update'].every(w => pathname.includes(w)) && !overlayObject.filter
        ) {
          setOverlay('reset');
        }
      });

      document.addEventListener("keydown", handleEsc, false);
    });

  }

  return { overlay, setOverlay };
};

watch(overlayObject, (newValue) => {
  if (import.meta.env.SSR) return; // ssr fix
  let values = allFalse(newValue);
  setTimeout(() => {
    if (!values) {
      document.body.style.setProperty("overflow", "hidden");
      document.body.style.setProperty("scrollbar-gutter", "stable");
      document.body.style.setProperty("touch-action", "none");
    } else {
      document.body.style.removeProperty("overflow");
      document.body.style.removeProperty("scrollbar-gutter");
      document.body.style.removeProperty("touch-action");
    }
  }, 0);
});
