<!-- eslint-disable vue/no-v-html -->
<template><!-- info navigation -->
<div id="firstnav" class="">
  <div class="">
    <NvInfoBar v-if="!infobarHide" @hide="hide()" />
  </div>
</div>
</template>

<script setup>
import { shallowRef } from "vue";
import NvInfoBar from '@/Components/Nav/NvInfoBar.vue';

const ssr = import.meta.env.SSR;

const infobarHide = shallowRef((!ssr) ? sessionStorage.getItem('infobarHide') : null);

const hide = () => {
  infobarHide.value = true;
  sessionStorage.setItem('infobarHide', infobarHide.value);
};

</script>
