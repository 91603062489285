<template>
  <div v-if="variationColors && variationColors.length > 1"
    class="hidden lg:flex flex-row flex-wrap items-center gap-1 mb-2 lg:max-w-[80%]">
    <div v-for="item in visibleColors" :key="item.atr.id"
      class="rounded-full w-4 h-4 border border-cx4-primary hover:opacity-75" :style="setColor(item.atr)"
      @mouseover="colorPicture = item?.mainPicture" @mouseleave="colorPicture = null"></div>
    <span v-if="totalCount > 0" class="text-base text-cx4-primary cursor-pointer hover:underline" @click="toggle">
      <template v-if="!isOpen">
        +{{ (totalCount) }}
      </template>
      <template v-else>
        <!-- -{{ (totalCount) }} -->
        <svg class="h-6 w-6 -ml-1 hover:underline cursor-pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
          fill="currentColor" aria-hidden="true">
          <path fill-rule="evenodd"
            d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
            clip-rule="evenodd" />
        </svg>
      </template>
    </span>
  </div>
</template>

<script setup>
import { ref, computed, watch } from "vue";

const props = defineProps({
  product: {
    type: Object,
    required: true,
  },
  limit: {
    type: Number,
    required: false,
    default: 3,
  },
});

const emits = defineEmits(['update:variationPicture']);

const isOpen = ref(false);

const colorPicture = ref(null);

const variationColors = computed(() => {
  var array = [];
  var mainPicture = null;
  const ac = (item) => {
    if (!array.some(a => a.atr.id === item.id)) {
      array.push({ id: item.id, mainPicture: mainPicture, atr: item });
    }
  };
  const vn = (item) => {
    if (Array.isArray(item)) {
      item.forEach(vn);
      return;
    } else if (item?.atrColors) {
      mainPicture = item.mainPicture;
      item.atrColors.forEach(ac);
      return;
    }
  };
  props.product.variationNames.forEach(vn);
  return [...new Map((array ?? [])
  .map(item => [item['id'], item])).values()] ?? [];

});

// console.log(variationColors.value);

const totalCount = (variationColors.value.length - props.limit) ?? 0;

const visibleColors = computed(() => {
  return isOpen.value ? variationColors.value : variationColors.value.slice(0, props.limit);
});

const setColor = (atr) => {
  let str = 'background-color:';
  if (atr.color) str += atr.color;
  else str += atr.name.toLowerCase();
  return str += ';';
};

const toggle = () => {
  isOpen.value = !isOpen.value;
};

watch(() => colorPicture.value, (newValue) => {
  emits('update:variationPicture', newValue);
});

</script>
