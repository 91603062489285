<template>
<div class="fixed z-[100] inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="flex items-center justify-center min-h-screen text-center sm:block sm:p-0">
    <!--
      Background overlay, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100"
        To: "opacity-0"
    -->
    <div class="fixed inset-0 bg-gray-800 bg-opacity-75 transition-opacity cursor-pointer" aria-hidden="true"
      @click="hide"></div>

    <!-- This element is to trick the browser into centering the modal contents. -->
    <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

    <!--
      Modal panel, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        To: "opacity-100 translate-y-0 sm:scale-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100 translate-y-0 sm:scale-100"
        To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    -->
    <div
      class="inline-flex align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-5xl sm:w-full p-3 sm:p-6 max-w-full max-h-[90vh] overflow-y-auto">
      <div class="text-center flex flex-col w-full">
        <h3 v-if="title" class="my-2 text-lg leading-6 font-medium text-cx1-primary">{{ title }}</h3>
        <div class="my-4">
          <slot />
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script setup>
import { onBeforeMount, onBeforeUnmount } from "vue";

defineProps({
  title: {
    type: String,
    required: false,
    default: "",
  },
});

const emits = defineEmits(['hide']);

onBeforeMount(() => {
  document.body.style.setProperty('overflow', 'hidden');
  document.addEventListener('keydown', handleEsc);
});

onBeforeUnmount(() => {
  document.body.style.removeProperty('overflow');
  document.removeEventListener('keydown', handleEsc);
});

const handleEsc = (e) => {
  if (e.code === 'Escape') emits('hide', true);
};

const hide = () => {
  emits('hide', true);
};

</script>
