<template>
<swiper v-bind="$attrs" :modules="data.modules">
  <swiper-slide v-for="(item, index) in elements" :key="index">
    <slot name="slide" :item="item" />
  </swiper-slide>
</swiper>
</template>

<script setup>
import { reactive, toRef } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay, Navigation, Pagination, Mousewheel, FreeMode } from 'swiper/modules';

defineOptions({
  inheritAttrs: false,
});

const props = defineProps({
  elements: {
    type: [Array, Object],
    required: false,
    default: () => { },
  },
});

const elements = toRef(props, "elements");

const data = reactive({
  modules: [Autoplay, Navigation, Pagination, Mousewheel, FreeMode],
});

</script>
