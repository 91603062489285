const webshopId = import.meta.env.VITE_WEBSHOP_ID;

const shopCss = (obj) => obj[webshopId] ?? '';

const shop = {
  1: 'mw',
  2: 'bao',
  3: 'bk',
  4: 'tt',
  5: 'ff',
  6: 'di',
  7: 'sm',
  8: 'pb',
  9: 'ck',
  10: 'rt',
  11: 'mwcom',
  12: 'twi',
}[webshopId];

export {
  shopCss,
  shop,
};
