
import { ref, computed } from 'vue';
import { router, usePage } from "@inertiajs/vue3";

const $page = usePage();

const locale = computed(() => `/${($page.props?.locale ?? 'fi')}`);

export const useLocale = () => {

  const lc = (url) => {

    if ((/^\/[a-z]{2}\//i).test(url)) return url; // locale already in url?

    url = url?.replace(/^\/|\/$/g, '') ?? ''; // trim url

    return `${locale.value}/${url}`;

  };

  return { locale, lc };

};

export const useLocaleChange = () => {

  const loading = ref(false);

  const changeLocale = (locale, data = {}, callback = null) => {

    if (loading.value) return false;

    router.visit(`/locale/${locale}`, {
      preserveScroll: true,
      replace: true,
      data,
      onStart: () => loading.value = true,
      onSuccess: () => { },
      onFinish: () => {
        loading.value = false;
        if (callback && typeof callback === 'function') {
          callback();
        }
      },
    });
  };

  return { loading, changeLocale };

};
