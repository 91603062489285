<!-- eslint-disable vue/no-v-html -->
<template>
<div v-if="device.isDesktop" class="hidden lg:block">
  <Tooltip placement="bottom" arrow="top-right" :select="true" :open="open"
    @close="open = false">

    <template #title>
      <div v-if="selectedCountry" class="lg:hover:underline flex items-center gap-1.5 lg:-ml-1"
        :class="{ 'underline': open }" @click="toggle()">
        <span class="flex-shrink-0" v-html="getFlagCdn(selectedCountry)"></span>
        <span class="lg:whitespace-nowrap">{{ selectedCountry.name }} / {{ selectedLocale.toUpperCase() }}</span>
      </div>
    </template>

    <template #content>
      <div v-if="open" class="flex flex-col flex-wrap gap-3 min-w-[160px]">
        <div class="flex flex-col">
          <span class="input-primary-label">{{ trans("country") }}</span>
          <select :value="$page.props.country" class="input-primary w-[inherit]" name="country_select"
            @change="changeCountry">
            <option v-for="country in store['countries']" :key="country.id" :value="country.id">
              {{ country.name }}
            </option>
          </select>
        </div>

        <div v-if="true" class="">
          <div class="flex flex-col">
            <span class="input-primary-label">{{ trans("language") }}</span>
            <select id="locale" v-model="selectedLocale" class="input-primary" name="locale" @change="changeLocale">
              <option v-for="loc in locales" :key="loc" :value="loc" :disabled="loc == 'en'">
                <span v-if="loc == 'en'">{{ loc.toUpperCase() }} - Coming soon</span>
                <span v-else>{{ loc.toUpperCase() }}</span>
              </option>
            </select>
          </div>
        </div>
      </div>
    </template>
  </Tooltip>

</div>

<div v-else class="lg:hidden">

  <ChevronToggle :show-icon="false">

    <template #title>
      <div v-if="selectedCountry" class="cursor-pointer text-white font-bold flex items-center gap-1.5 lg:-ml-1">
        <span class="flex-shrink-0" v-html="getFlagCdn(selectedCountry)"></span>
        <span class="lg:whitespace-nowrap">{{ selectedCountry.name }} / {{ selectedLocale.toUpperCase() }}</span>
      </div>
    </template>

    <template #custom-icon="scope">
      <div class="flex flex-1 items-center justify-end cursor-pointer">
        <MinusIcon v-if="scope.open" class="shrink-0 h-4 w-4 lg:h-5 lg:w-5 text-white" />
        <PlusIcon v-else class="shrink-0 h-4 w-4 lg:h-5 lg:w-5 text-white" />
      </div>
    </template>

    <div v-if="open" class="flex flex-col flex-wrap gap-3 mt-4">

      <div class="flex flex-col">
        <span class="input-primary-label text-cx2-primary">{{ trans("country") }}</span>
        <select :value="$page.props.country" class="input-primary w-[inherit] text-cx1-primary" name="country_select"
          @change="changeCountry">
          <option v-for="mc in store['countries']" :key="mc.id" :value="mc.id">
            {{ mc.name }}
          </option>
        </select>
      </div>

      <div v-if="true" class="">
        <div class="flex flex-col">
          <span class="input-primary-label text-cx2-primary">{{ trans("language") }}</span>
          <select id="locale" v-model="selectedLocale" class="input-primary text-cx1-primary" name="locale"
            @change="changeLocale">
            <option v-for="loc in locales" :key="loc" :value="loc" :disabled="loc == 'en'">
              <span v-if="loc == 'en'">{{ loc.toUpperCase() }} - Coming soon</span>
              <span v-else>{{ loc.toUpperCase() }}</span>
            </option>
          </select>
        </div>
      </div>

    </div>

  </ChevronToggle>


</div>
</template>

<script setup>
import { ref, computed, defineAsyncComponent } from "vue";
import { useStore } from "g/Composables/GlobalStore.js";
import { shop } from "@/Composables/Shop.js";
import { useOverlay } from "@/Composables/Overlay.js";
import { useDevice } from "g/Composables/GlobalDevice";
import { router, usePage } from '@inertiajs/vue3';
import { MinusIcon, PlusIcon } from '@heroicons/vue/solid/index.js';

const Tooltip = defineAsyncComponent(() => import("@/Shared/Tooltip.vue"));
const ChevronToggle = defineAsyncComponent(() => import("@/Shared/ChevronToggle.vue"));

const props = defineProps({
  forceOpen: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const { setOverlay } = useOverlay();

const store = useStore();

const $page = usePage();

const { device } = useDevice();

const locales = ref($page.props.locales ?? []);

const selectedLocale = ref($page.props.locale);

const open = ref(props.forceOpen);

const selectedCountry = computed(() => {
  const selectedCountryId = parseInt($page.props.country);
  return store['countries'].find((item) => item.id === selectedCountryId);
});

const shopRedirect = () => {
  const redirect = {
    mw: {
      'en': 'https://mwebstore.com/',
    },
    mwcom: {
      'fi': 'https://mwebstore.fi/',
    },
  };
  let url = redirect[shop][selectedLocale.value];
  if (url) {
    window.location.replace(url);
  }
};

const changeLocale = function () {
  switch (shop) {
    case 'mw':
    case 'mwcom':
      shopRedirect();
      break;
    default:
      router.visit(`/locale/${selectedLocale.value}`);
      break;
  }
};

const changeCountry = function (e) {
  const country = store['countries'].find(
    (item) => item.id === parseInt(e.target.value),
  );
  if (country) {
    let url = window.location.href.split("?")[0];
    router.visit(url + "?country=" + country.alpha2);
  }
};

const getFlagCdn = (country) => {
  if (!country) return '';
  let alpha2 = country.alpha2.toLowerCase() ?? '';
  let name = country.name ?? '';
  return (`<img src="https://flagcdn.com/${alpha2}.svg" width="18" alt="${name}">`);
};

const toggle = () => {
  open.value = !open.value;
  setOverlay('reset');
};

</script>
