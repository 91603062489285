import { shop } from "@/Composables/Shop.js";
import { useLocale } from "g/Composables/GlobalLocale.js";

const { lc } = useLocale();

export const useLinks = () => {

  const privacy = {
    mw: lc('/page/toimitusehdot-maksutavat/13611'),
    mwcom: lc('/page/shipping-payment-methods/13611'),
  }[shop];

  const shippingTerms = {
    mw: lc('/pages/toimitus-ja-ostoehdot/9'),
    mwcom: lc('/pages/toimitus-ja-ostoehdot/9'),
  }[shop];

  const facebook = {
    mw: 'https://www.facebook.com/mwebstorefi/',
    mwcom: 'https://www.facebook.com/mwebstorecom/',
  }[shop];

  const youtube = {
    mw: 'https://www.youtube.com/channel/UC11O0_GqiA4gX-IIpsYeijQ',
    mwcom: 'https://www.youtube.com/channel/UC11O0_GqiA4gX-IIpsYeijQ',
  }[shop];

  const linkedin = {
    mw: 'https://fi.linkedin.com/company/pro-nutrition-finland-oy',
    mwcom: 'https://fi.linkedin.com/company/pro-nutrition-finland-oy',
  }[shop];

  const twitter = {
    mw: 'https://www.instagram.com/mwebstorefi/',
    mwcom: 'https://www.instagram.com/mwebstorefi/',
  }[shop];

  const instagram = {
    mw: 'https://www.instagram.com/mwebstorefi/',
    mwcom: 'https://www.instagram.com/mwebstorecom/',
  }[shop];

  return {
    privacy,
    shippingTerms,
    facebook,
    youtube,
    linkedin,
    twitter,
    instagram,
  };
};
