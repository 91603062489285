import { shallowRef, onBeforeMount, onBeforeUnmount, watchEffect } from "vue";

const ssr = () => import.meta.env.SSR;

const isFixed = shallowRef((!ssr) ? parseInt(window?.scrollY > 0) ?? false : false);

const navHeight = shallowRef(0);

export const useFixed = () => {

  // Detects desktop scroll position
  var timeout1;
  const navScrollCheck = () => {
    clearTimeout(timeout1);
    timeout1 = setTimeout(() => {
      isFixed.value = window.scrollY > 0;
    }, 10);
  };

  // gets nav height dynamically
  var timeout2;
  const setHeight = () => {
    clearTimeout(timeout2);
    timeout2 = setTimeout(() => {
      navHeight.value = document.querySelector('nav')?.clientHeight;
    }, 15);
  };

  onBeforeMount(() => {
    // if (ssr()) return;
    document.addEventListener("scroll", navScrollCheck);
    window.addEventListener("resize", setHeight);
    navScrollCheck();
  });

  onBeforeUnmount(() => {
    // if (ssr()) return;
    document.removeEventListener("scroll", navScrollCheck);
    document.removeEventListener("resize", setHeight);
  });

  watchEffect(() => {
    if (ssr()) return;
    isFixed.value;
    setHeight();
  });

  return { isFixed, navHeight };

};
