<template>
<div>
  <!-- <div class="border-t border-gray-200 py-2">
    <div v-if="!retailer" class="flex border border-gray-200 h-8 px-4 items-center text-xs">
      <span v-if="deltaToFreeShippingCost !== '0.00 EUR'">
        <span v-if="deltaToFreeShippingCost">
          {{ trans('delta_to_free_shipping_text', {'amount': deltaToFreeShippingCost}) }}
        </span>
      </span>
      <span v-else>
        {{ trans('free_delivery_active') }}
      </span>
    </div>
  </div> -->


  <ChevronToggle class="lg:mt-6 mt-5 mb-2 lg:mb-4">
    <template #title>
      <h3 class="flex justify-start font-bold text-left text-xl">{{ trans('look_also') }}</h3>
    </template>
    <div class="">
      <div>
        <div class="max-w-default px-0  mx-auto relative">
          <div class="max-w-default mx-auto px-0 relative">
            <CustomSlider :elements="buyAlsoProducts" :slider-props="{ breakpoints: productBreakpoints }"
              :hide="{ navigation: true }" unique-key="cart-sidemenu" />
          </div>
        </div>
      </div>
    </div>
  </ChevronToggle>

  <!-- <div v-if="buyAlsoProducts.length" class="">
    <div class="flex flex-row lg:justify-start items-center w-full">
      <div class="flex gap-4 justify-start items-center lg:mt-6 mt-4 mb-2 lg:mb-4">
        <h3 class="flex justify-start font-bold text-left text-xl">{{ trans('look_also')
          }}</h3>
      </div>
    </div>
    <div>
      <div class="max-w-default px-0  mx-auto relative">
        <div class="max-w-default mx-auto px-0 relative">
          <CustomSlider :elements="buyAlsoProducts" :slider-props="{ breakpoints: productBreakpoints }"
            :hide="{ navigation: true }" unique-key="cart-sidemenu" />
        </div>
      </div>
    </div>
  </div>
   -->
</div>

</template>

<script setup>
import { watch, onErrorCaptured, inject } from "vue";
import CustomSlider from "@/Shared/CustomSlider.vue";
// import { usePage } from "@inertiajs/vue3";
import { useCart } from "g/Store/GlobalUseCart.js";
import ChevronToggle from "@/Shared/ChevronToggle.vue";

const { deltaToFreeShippingCost, buyAlsoProducts } = inject('ajaxSection', null);

const cart = useCart();

// const retailer = computed(() => {
//   return $page.props.retailer;
// });

watch(() => cart.value?.cartitems?.length, () => {
  updateAdditionalInfo();
});

updateAdditionalInfo();

function updateAdditionalInfo() {
  if (import.meta.env.SSR) return false;
  window.axios
    .get('/cart/info?buyAlso&deltaToFreeShippingCost')
    .then((response) => {
      const data = response.data;
      buyAlsoProducts.value = data.buyAlso ?? [];
      deltaToFreeShippingCost.value = data?.deltaToFreeShippingCost ?? null;
    })
    .catch((error) => {
      console.error(error);
    });
}

const productBreakpoints = {
  0: {
    slidesPerView: 2.5,
    spaceBetween: 5,
  },
  650: {
    slidesPerView: 4.5,
    slidesPerGroup: 1,
    spaceBetween: 10,
  },
  1200: {
    slidesPerView: 3.5,
    slidesPerGroup: 1,
    spaceBetween: 10,
  },
};

onErrorCaptured((err) => {
  console.error(err);
  return false;
});

</script>
