<template><template v-if="style === 'Product'">
  <div class=" font-bold text-lg md:text-xl">
    <div v-if="parseInt(price.discount_percent) > 0" class="flex gap-2">
      <span class="text-cx4-primary text-lg md:text-xl font-normal line-through" v-text="oldPrice"></span>
      <span class="text-cx3-primary text-lg md:text-xl font-bold" v-text="mainPrice"></span>
      <slot />
    </div>
    <span v-else class="text-cx1-primary text-lg md:text-xl font-bold" v-text="mainPrice"></span>
  </div>
  <div v-if="showVatPercent" class="font-normal text-sm text-cx1-primary">
    {{ trans("sis_alv") }} {{ vatPercent }}%
  </div>
</template>

<template v-else>
  <div class="flex flex-col">
    <div v-if="parseInt(price.discount_percent) > 0" class="flex gap-2">
      <span class="text-cx4-primary line-through" v-text="oldPrice"></span>
      <span class="text-cx3-primary" v-text="mainPrice"></span>
    </div>
    <span v-else class="text-cx1-primary" v-text="mainPrice"></span>
    <span v-if="showVatPercent" class="font-normal text-cx1-primary">
      {{ trans("sis_alv") }} {{ vatPercent }}%
    </span>
  </div>
</template></template>


<script setup>
import { usePage } from '@inertiajs/vue3';;
import { ref, watchEffect } from "vue";

const props = defineProps({
  price: {
    type: Object,
    required: true,
  },
  priceData: {
    type: Object,
    required: false,
    default: () => { },
  },
  showFreePrice: {
    type: Boolean,
    required: false,
    default: false,
  },
  component: {
    type: String,
    required: false,
    default: () => '',
  },
  style: {
    type: String,
    required: false,
    default: () => '',
  },
  showVatPercent: {
    type: Boolean,
    required: false,
    default: false,
  },
  singleItem: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const $page = usePage();

const style = (props.style !== '') ? props.style : props.component;

const showVat = $page.props.showVat;

const mainPrice = ref(0);

const oldPrice = ref(0);

const vatPercent = ref((showVat) ? props.priceData.vat_percent : 0);

const updatePrice = () => {

  var keys = [];

  var array = {
    default: ['main_price_with_vat', 'old_price_with_vat'],
    single: ['price_with_vat', 'price_without_discount_with_vat'],
    cart: ['main_price_with_vat', 'old_price_with_vat'],
  };

  if (props.component === 'Cart') {
    if (props.singleItem) {
      keys = array['single'];
    }
    keys = array['cart'];
  } else {
    keys = array['default'];
  }

  if (!showVat) {
    keys = keys.map(function (d) {
      return d.replace('_with_vat', '');
    });
  }

  mainPrice.value = props.price[keys[0]];
  oldPrice.value = props.price[keys[1]];

  // Cosmetic (used in free product container)
  if (props.showFreePrice) {
    mainPrice.value = '0 €';
  }

};

watchEffect(() => {
  props.price;
  updatePrice();
});


</script>
