import { ref } from 'vue';

const wishListItems = ref([]);

const wishListNumbers = ref([]);

const wishListLoginError = ref([]);

const getWishlist = async (set) => {

  if (import.meta.env.SSR === true) return false;

  // console.log(props);
  var props = {
    type: 'fetch',
    number: null,
    products: false,
  };

  props = Object.assign({}, props, set);
  // console.log(props);

  await window.axios
    .post('/wishlist', props)
    .then((response) => {
      const data = response.data;
      if (data) {
        // console.log(data);
        let error = data?.error;

        if (error !== 'login') {

          wishListNumbers.value = data?.wishlistNumbers ?? [];
          wishListItems.value = data?.wishlist_products ?? [];

        } else if (error === 'login' && props.type === 'toggle') {

          let array = wishListLoginError.value;
          array.push(props.number);

          setTimeout(() => {
            wishListLoginError.value = array.filter((number) => number !== number);
          }, 8000);

        }
      }
    })
    .catch((error) => {
      console.error(error);
      wishListItems.value = [];
      wishListNumbers.value = [];
    });
};

const inWishlist = (number) => {
  return wishListNumbers.value.includes(number);
};

const refreshWishlist = (refresh = null, props = {}) => {
  if (!wishListNumbers.value.length || refresh) getWishlist(props);
};

const toggleWish = async (number) => {
  const props = {
    type: 'toggle',
    number: number,
    products: false,
  };
  await getWishlist(props);
};

export const useWishlist = () => {
  return {
    wishListItems,
    wishListNumbers,
    wishListLoginError,
    toggleWish,
    inWishlist,
    refreshWishlist,
  };
};

export { //TODO veke
  wishListItems,
  wishListLoginError,
  toggleWish,
  inWishlist,
  refreshWishlist,
};
