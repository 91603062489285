<template>
<svg xmlns="http://www.w3.org/2000/svg" width="24.524" height="24.842" viewBox="0 0 24.524 24.842">
  <path d="M54.706-855.158a2.224,2.224,0,0,1-1.638-.683,2.246,2.246,0,0,1-.679-1.642,2.224,2.224,0,0,1,.683-1.638,2.246,2.246,0,0,1,1.642-.679,2.224,2.224,0,0,1,1.638.683,2.246,2.246,0,0,1,.679,1.642,2.224,2.224,0,0,1-.683,1.638A2.246,2.246,0,0,1,54.706-855.158Zm12.432,0a2.224,2.224,0,0,1-1.638-.683,2.246,2.246,0,0,1-.679-1.642,2.224,2.224,0,0,1,.683-1.638,2.246,2.246,0,0,1,1.642-.679,2.224,2.224,0,0,1,1.638.683,2.246,2.246,0,0,1,.679,1.642,2.224,2.224,0,0,1-.683,1.638A2.246,2.246,0,0,1,67.137-855.158Zm-13.857-20.3,3.274,6.8h8.868l3.729-6.8Zm-1.015-2.072H70.583a1.186,1.186,0,0,1,1.087.647,1.242,1.242,0,0,1,.01,1.3L67.411-867.9a2.669,2.669,0,0,1-.9.953,2.226,2.226,0,0,1-1.216.352H55.932l-1.616,2.984H69.462v2.072H54.5a2.144,2.144,0,0,1-1.958-.989,1.972,1.972,0,0,1-.01-2.119l1.885-3.46-4.641-9.821H47.334V-880H51.1Zm4.289,8.868h0Z" transform="translate(-47.334 880)"/>
<defs>
<clipPath>
<rect width="20" height="20" fill="white"/>
</clipPath>
</defs>
<circle v-if="itemCount > 0" cx="18" cy="6" r="6" fill="#000"/>
  <text v-if="itemCount > 0" x="75%" y="8%" text-anchor="middle" stroke="#fff" stroke-width="1px" font-family="Open Sans" font-size="7px" dy="1em">
    {{itemCount}}
  </text>
</svg>
</template>

<script setup>
import { computed } from "vue";
import { useCart } from "g/Store/GlobalUseCart.js";

const cart = useCart();

const itemCount = computed(() => cart.value?.amount);
</script>
