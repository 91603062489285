<template>
  <span v-for="(values, title) in attributes" :key="title" class="">
    {{ title + ': ' }}
    <template v-for="(atr, index) in values" :key="atr.id">
      {{ trim((atr.name + ', '), index, values) }}
    </template>
  </span>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  item: {
    type: Object,
    required: true,
  },
});

const attributes = computed(() => {
  let array = props.item?.attributes ?? [];
  let newArray = {};
  Object.values(array).forEach(atr => {
    let key = atr['typeName'];
    if (!newArray[key]) newArray[key] = [];
    newArray[key].push(atr);
  });
  return newArray;
});

const trim = (str, index, obj) => { // trim and remove , from end of string
  let newStr = str.replace(/,/g, '').trim();
  return (index == obj.length - 1) ? newStr : str;
};

</script>
