<template>
<div v-if="chunk.length" class="space-y-12">
  <div v-for="(item, index) in chunk" :key="index" class="space-y-2" @click="toggleChildren(item)">

    <div class="text-xl font-bold text-cx1-primary mb-3 cursor-pointer active:underline hover:underline w-fit">
      <component :is="!hasChildren(item) ? 'Link' : 'span'" :href="item?.link" class="flex items-center gap-2">
        {{ item.name }}
        <ChevronRightIcon v-if="hasChildren(item)" class="shrink-0 h-6 w-6" :class="{ 'rotate-90': isOpen(item) }" />
      </component>
    </div>

    <template v-if="isOpen(item) && hasChildren(item)">
      <div class="overflow-y-auto max-h-[400px] pr-2">
      <div v-for="child in item.children" :key="child.id">
        <Link class="flex items-center gap-2 text-base text-cx1-primary active:underline hover:underline w-fit"
          :href="getLink(child)">
        <span>{{ child.name }}</span>
        </Link>
      </div>
      <div class="pt-2">
        <Link class="flex items-center gap-2 text-base text-cx4-primary active:underline hover:underline w-fit"
          :href="getLink(item)">
        <span>{{ `${trans('all')} ${item.name.toLowerCase()}` }}</span>
        </Link>
      </div>
      </div>
    </template>

  </div>
</div>
</template>

<script setup>
import { shallowRef, inject } from "vue";
import { ChevronRightIcon } from "@heroicons/vue/solid/index.js";

defineProps({
  chunk: {
    type: Array,
    required: true,
  },
});

const { getLink } = inject('MegaMenu', null);

const activeItem = shallowRef(null);

const toggleChildren = (item) => {
  activeItem.value = activeItem.value?.id == item?.id ? null : item;
};

const isOpen = (item) => activeItem.value?.id === item.id;

const hasChildren = (item) => item.children?.length;

</script>
