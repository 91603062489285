<!-- eslint-disable vue/no-v-html -->
<template>
<div v-if="notifications && notifications.length" class="bg-cx3-primary text-cx2-primary px-3">
  <div class="mx-auto min-h-[30px] flex items-center relative leading-[30px] py-0.5 lg:px-3 2xl:px-0">
    <Slider :elements="notifications" :navigation="false"
      :autoplay="(notifications.length > 1) ? data.sliderAutoplay : false" :breakpoints="data.sliderBreakpoints"
      :loop="true">
      <template #slide="slide">
        <div class="flex mx-auto px-5 lg:px-12 items-center justify-between h-full">
          <div class="flex gap-2 flex-1 items-center justify-center">
            <div class="text-base font-semibold cursor-default a-underline text-center">
              <span class="infobar-text text-center" v-html="slide.item"></span>
            </div>
          </div>
        </div>
      </template>
    </Slider>
    <div class="flex gap-2 cursor-pointer group z-10 items-center absolute top-2 right-0 lg:static" @click="emits('hide', true)">
  <span class="text-xs group-hover:underline hidden lg:block">{{ trans('close') }}</span>
  <XCircleIcon class="w-[18px] h-[18px] lg:w-[24px] lg:h-[24px] group-hover:opacity-90" />
</div>
  </div>
</div>
</template>

<script setup>
import { usePage } from "@inertiajs/vue3";
import { computed, inject } from "vue";
import Slider from "@/Shared/Slider.vue";
import { XCircleIcon } from "@heroicons/vue/outline/index.js";

const { store } = inject('Nav', null);

const $page = usePage();

const notifications = computed(() => {
  return [...store['infobar'], ...($page.props?.notifications ?? [])];
});

const emits = defineEmits(['hide']);

const data = {
  sliderAutoplay: {
    enable: true,
    delay: 3000,
    disableOnInteraction: false,
    // speed: 1000,
  },
  sliderBreakpoints: {
    0: {
      slidesPerView: 1,
      slidesPerGroup: 1,
      spaceBetween: 20,
    },
  },
};
</script>
<style>
.infobar-text a {
  @apply underline
}
</style>
