<template>
  <a target="_blank" title="Facebook" class="p-1" :href="facebook"> <!-- TODO: muuta linkit -->
    <FacebookIcon v-bind="$attrs" />
  </a>
  <a target="_blank" title="Youtube" class="p-1" :href="youtube">
    <YoutubeIcon v-bind="$attrs" />
  </a>
  <a target="_blank" title="Linkedin" class="p-1" :href="linkedin">
    <LinkedinIcon v-bind="$attrs" />
  </a>
  <a target="_blank" title="Instagram" class="p-1" :href="instagram">
    <InstagramIcon v-bind="$attrs" />
  </a>
</template>

<script setup>
import { useLinks } from "@/Composables/Links.js";
import FacebookIcon from "@/Icons/FacebookIcon.vue";
import LinkedinIcon from "@/Icons/LinkedinIcon.vue";
import YoutubeIcon from "@/Icons/YoutubeIcon.vue";
import InstagramIcon from "@/Icons/InstagramIcon.vue";

defineOptions({
  inheritAttrs: false,
});

const { facebook, youtube, linkedin, instagram } = useLinks();

</script>
