<template>
  <div v-if="stars !== undefined">
    <div class="flex items-center gap-1">
      <svg v-for="s in stars_full" :key="s" class="text-cx1-primary flex-shrink-0 h-4 lg:h-4" viewBox="0 0 20 20" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path d="M3.825 20L6.15 12.4L0 8H7.6L10 0L12.4 8H20L13.85 12.4L16.175 20L10 15.3L3.825 20Z" fill="currentColor" />
      </svg>
      <svg v-for="s in stars_half" :key="s" class="text-cx1-primary flex-shrink-0 h-4 lg:h-4" viewBox="0 0 20 20" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <defs>
          <linearGradient id="half">
            <stop offset="50%" class="text-cx1-primary" stop-color="currentColor" />
            <stop offset="50%" class="text-cx4-primary" stop-color="currentColor" />
          </linearGradient>
        </defs>
        <path d="M3.825 20L6.15 12.4L0 8H7.6L10 0L12.4 8H20L13.85 12.4L16.175 20L10 15.3L3.825 20Z" fill="url(#half)" />
      </svg>
      <svg v-for="s in stars_empty" :key="s" class="text-cx4-primary flex-shrink-0 h-4 lg:h-4" viewBox="0 0 20 20"
        fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.825 20L6.15 12.4L0 8H7.6L10 0L12.4 8H20L13.85 12.4L16.175 20L10 15.3L3.825 20Z" fill="currentColor" />
      </svg>
    </div>
  </div>
</template>

<script setup>

// import { computed } from "vue";

const props = defineProps({
  stars: {
    type: [Number, String],
    required: false,
    default: undefined,
  },
});

const stars_full = props.stars > 0 ? Math.floor(props.stars) : 0;

const stars_half = Math.ceil(props.stars % 1);

const stars_empty =  5 - Math.ceil(props.stars);

// const stars_full = computed(() => {
//   return props.stars > 0 ? Math.floor(props.stars) : 0;
// });

// const stars_half = computed(() => {
//   return Math.ceil(props.stars % 1);
// });

// const stars_empty = computed(() => {
//   return 5 - Math.ceil(props.stars);
// });

</script>
