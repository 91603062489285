<template>
<div class="flex flex-col gap-2">
  <div v-if="props.data.length >= 2" class="flex flex-wrap gap-1 text-xs">

    <div v-for="item, index in firstLevelItems" :key="index"
      class="border border-ui-divider bg-white rounded-lg px-2 shadow-md">
      {{ item.name }}
    </div>
  </div>
  <div class="flex flex-wrap gap-x-4 gap-y-1 text-xs">
    <div v-for="item, index in secondLevelItems" :key="index"
      class="flex gap-1 items-center bg-white border border-cx2 rounded-lg px-2 shadow-md">
      <div class="h-1 w-1 rounded-full" :style="{ 'background-color': item.color }"></div>
      <div>{{ item.name }}</div>
    </div>
  </div>
</div>
</template>

<script setup>

import { computed } from "vue";

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
});

// const activeParentId = ref(props.data[0][0].id);

const firstLevelItems = computed(() => {
  let array = props.data[0];
  return array.filter((obj, pos, arr) => {
    return arr.map(mapObj => mapObj['name']).indexOf(obj['name']) === pos;
  });
});

const secondLevelItems = computed(() => {
  if (props.data.length < 2) {
    return props.data[0];
  }
  let map = props.data[1].map(({ name, buyable, color }) => ({ name, buyable, color }));
  return map.reduce((accumulator, current) => {
    let item = accumulator.find((item) => item.name === current.name);
    if (!item) {
      accumulator.push(current);
    } else if (current.buyable) { // green
      item.buyable = current.buyable;
      item.color = current.color;
    } else if (!current.buyable && !item.buyable && !item.color.startsWith('#F3')) { // yellow
      item.color = current.color;
    }
    return accumulator;
  }, []);
  // return props.data[1].filter(item => item.parent === activeParentId.value);
});

// const sortSizes = (array) => {
//   const ORDER = ['one size', 'xxxs', '3xs', 'xxs', '2xs', 'xs', 's', 'm', 'l', 'xl', '2xl', 'xxl', '3xl', 'xxxl', '4xl', 'xxxxl'];
//   return array.sort((a, b) => {
//     a = a['name'].toLowerCase();
//     b = b['name'].toLowerCase();

//     let nra = parseInt(a);
//     let nrb = parseInt(b);

//     if ((ORDER.indexOf(a) != -1)) nra = NaN;
//     if ((ORDER.indexOf(b) != -1)) nrb = NaN;

//     if (nrb === 0) return 1;
//     if (nra && !nrb || nra === 0) return -1;
//     if (!nra && nrb) return 1;
//     if (nra && nrb) {
//       if (nra == nrb) {
//         return (a.substr(('' + nra).length)).localeCompare((a.substr(('' + nra).length)));
//       } else {
//         return nra - nrb;
//       }
//     } else {
//       return ORDER.indexOf(a) - ORDER.indexOf(b);
//     }
//   });
// };

</script>
