import { shop } from '@/Composables/Shop.js';

const placeholderUrl = {
  mw: '/pronutrition/mw_logo.png',
  mwcom: '/pronutrition/mwebstore.png',
}[shop];

const apply = (target) => { // fixes placeholder
  // if (!target.classList.value.includes('object-contain')) {
  target.classList.remove('object-cover', 'object-contain');
  target.classList.add('object-scale-down', 'px-3'); // <-- add here
  // }
};

const swap = (e) => {
  let target = e.target;
  if (target) {
    console.log("image failed to load: ", target.src);
    target.src = placeholderUrl;
    apply(target);
  }
};

export {
  placeholderUrl,
  swap,
};
